import { useQuery } from "@tanstack/react-query"
import { Cliente } from "../../models/model"
import { getJwtToken } from "../auth/auth"
import { apiEndpoint } from "./config"

export function useCliente() {
  const path = '/profilo/cliente'
  return useQuery<Cliente>({
    queryKey: ['cliente'],
    queryFn: async (context) => {
      const token = await getJwtToken()

      return fetch(apiEndpoint + path, {
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `bearer ${token}` as string,
        }),
      })
        .then(res => {return res.json() as Promise<Cliente>})
    }
  })
}