import {NavLink, useNavigate} from "react-router-dom";
import {logOutUser} from "../../services/auth/auth";

export default function Profilo() {
  return <section>
    <h1>Profilo</h1>
    <div className="flex flex-col justify-between">
      <div className="mt-7">
        <nav className="profile-menu">
          <NavLink to="/targhe" >Targhe</NavLink>
          <NavLink to="/informazioni-personali" >Informazioni Personali</NavLink>
          <NavLink to="/aiuto" >Aiuto</NavLink>
          <NavLink to="/privacy" >Privacy</NavLink>
          <span className="cursor-pointer" onClick={() => {
            logOutUser().then(() => {
              window.location.reload()
            })
          }}>Logout</span>
        </nav>
      </div>
    </div>
  </section>
}
