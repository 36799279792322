import { useContext, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.scss";
import { Callback } from "./components/Callback";
import { Layout } from "./components/Layout";
import { ForgotPassword } from "./components/NoAuth/ForgotPassword";

import { ThemeProvider, createTheme } from "@mui/material";
import { QueryClientProvider } from "@tanstack/react-query";
import { LoginHandler } from "./components/NoAuth/Login";
import { TokenContext } from "./providers/TokenProvider";
import { queryClient } from "./services/api/config";
import { CognitoAuthContext, UserSession, getJwtToken, getSession, isValid, logOutUser } from "./services/auth/auth";
import { getTemporatyToken, initTemporarySession, isTemporaryTokenValid } from "./services/auth/temporary_auth";
const theme = createTheme({
  typography: {
    fontFamily: `"Inter", sans-serif`,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": {
              borderColor: "#185a7d ",
            },
          },
        },
      },
    },
  },
});
function App() {
  const [auth_type, setAuthType] = useState<"with_credentials" | "oidc" | null>(null);
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CognitoAuthContext.Provider value={auth_type}>
          <BrowserRouter basename="/app">
            <Routes>
              <Route path="/*" element={<ProtectedRoute redirectPath="/login" />} />
              <Route path="/callback" element={<Callback />} />
              <Route path="login" element={<LoginHandler changeLoginType={(auth_type) => setAuthType(auth_type)} />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
            </Routes>
          </BrowserRouter>
        </CognitoAuthContext.Provider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
function ProtectedRoute(props: { redirectPath?: string }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Example: Get a specific query parameter
  const queryParamsToken = queryParams.get("token");


  const auth_type = useContext(CognitoAuthContext);
  const redirectPath = props.redirectPath || "/login";
  const [cognitoUserSession, setCognitoUserSession] = useState<UserSession | undefined>();
  const [isATmporarySession, setIsATemporarySession] = useState<boolean>(false);
  const [sessionChecked, setSessionChecked] = useState<boolean>(false);
  const [token, setToken] = useState<string | undefined>(undefined);
  // const token2 = cognitoUserSession?.getIdToken()?.getJwtToken();
  const value = useMemo(() => ({ token }), [token]);
  const navigate = useNavigate();

  useEffect(() => {
    let ignore = false;
    const checkSession = async () => {
      if (ignore) {
        return;
      }
      try {
        /* Controllo se c'è un token e se è valido */
        if (queryParamsToken && isTemporaryTokenValid(queryParamsToken)) {
          setToken(queryParamsToken);
          setSessionChecked(true);
          initTemporarySession(queryParamsToken);
          // Rimuovi i parametri di query dall'URL
          navigate(location.pathname, { replace: true });
          // Do something with the token
        }
        /* Controllo se c'è una sessione temporanea */
        const temporaryToken = getTemporatyToken();
        if(temporaryToken){
          if(isTemporaryTokenValid(temporaryToken)){
            setSessionChecked(true);
            setToken(temporaryToken);
            setIsATemporarySession(true);
          }else{
            await logOutUser();
            setSessionChecked(true);
          }
        }else{
          /* Flusso normale */
          const cognitoUserSession = await getSession();
          const is_session_valid = await isValid();
          const jwtToken = await getJwtToken();
          if (is_session_valid && jwtToken) {
            setCognitoUserSession(cognitoUserSession);
            setToken(jwtToken);
          }
          setSessionChecked(true);
        }
      } catch (err) {
        await logOutUser();
        setCognitoUserSession(undefined);
        setSessionChecked(true);
      }
    };
    checkSession();
    return () => {
      ignore = true;
    };
  }, [auth_type, navigate, queryParamsToken, location.pathname]);

  return (
    <>
      {!sessionChecked && (
        <div style={{ height: "100vh", width: "100vw" }}>
          <div className="w-100 h-full login-bg flex items-center justify-center">
            <div className="flex flex-col items-center ">
              <img src={`${process.env.PUBLIC_URL}/Milanofiori-tagline-Main-logo-H-N.png`} className="mb-11" alt="" style={{ maxWidth: "30vw" }} />
              <h3>Stiamo controllando la tua identità..</h3>
            </div>
          </div>
        </div>
      )}
      {sessionChecked && (!cognitoUserSession && !isATmporarySession) && <Navigate to={redirectPath} replace />}
      {(sessionChecked === true && (!!cognitoUserSession || !!isATmporarySession) && !!token ) && (
        <TokenContext.Provider value={value as { token: string }}>
          <Routes>
            <Route path="/*" element={<Layout />} />
          </Routes>
        </TokenContext.Provider>
      )}
    </>
  );
}
export default App;

