import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from "react-router-dom";
export function PageTitle(
  {
    title,
    back_link,
    show_back_button_on_mobile,
  }: {
    title: string,
    back_link?: string,
    show_back_button_on_mobile?: boolean,
  }) {
  const navigate = useNavigate()
  const handleClick = () => {
    if (!back_link) navigate(-1)
    else navigate(back_link)
  }
  return <div className="page-title-container">
    {show_back_button_on_mobile && (
      <button onClick={() => { handleClick() }} >
        <ChevronLeftIcon />
      </button>
    )}
    <h1>{title}</h1>
  </div>
}