import { Skeleton } from "@mui/material";

export default function SkeletonListPrenotazioni() {
  return <div className="mt-7">
    <ol>
      <li>
        <Skeleton variant="rounded" width={"50%"} height={22} />
      </li>
      <li>
        <Skeleton variant="rounded" className="mt-4" height={100} />
      </li>
      <li>
        <Skeleton variant="rounded" className="mt-4" height={100} />
      </li>
      <li>
        <Skeleton variant="rounded" className="mt-9" width={"50%"} height={22} />
      </li>
      <li>
        <Skeleton variant="rounded" className="mt-4" height={100} />
      </li>
      <li>
        <Skeleton variant="rounded" className="mt-4" height={100} />
      </li>
    </ol>
  </div>
}