import { useUtente } from "../../services/api/utente";
import { PageTitle } from "../shared/PageTitle";

export default function Privacy() {

  const {
    data: utente,
    error: isUtenteError,
    isLoading: isUtenteLoading,
  } = useUtente()
  return <section>
    <PageTitle show_back_button_on_mobile={true} title="Privacy" />

    <div className="mt-7">
      <p>
        Ai sensi dell’articolo 13 del Regolamento (UE) 2016/679 (General Data Protection Regulation), la informiamo che i suoi dati personali inseriti all’interno del form, da lei liberamente conferiti, saranno trattati da Milanofiori 2000 s.r.l., in qualità di Titolare del trattamento, per consentirle di effettuare il parcheggio della vettura. La base giuridica del trattamento indicato è l’esecuzione di misure precontrattuali adottate su richiesta dell’interessato (art. 6 comma 1 lett. b) del Regolamento). Il conferimento dei dati è libero, tuttavia il mancato conferimento non permetterà alla nostra Società di adempiere a quanto da lei richiesto. Qualora decida di conferirci i suoi dati, la informiamo che gli stessi saranno conservati fino al termine del periodo di parcheggio. La informiamo inoltre che i dati da lei liberamente forniti saranno trattati esclusivamente da personale autorizzato dal Titolare. Le ricordiamo infine che, ai sensi degli articoli 15 e ss. del GDPR, lei ha diritto di ottenere la conferma dell’esistenza o meno di dati che la riguardano, di ottenere l’indicazione dell’origine e delle finalità e modalità del trattamento, l’aggiornamento, la rettificazione, l’integrazione dei dati nonché la cancellazione dei dati trattati in violazione di legge o qualora sussista uno dei motivi specificati dall’articolo 17 del GDPR. Per esercitare tali diritti può rivolgersi al Titolare del trattamento scrivendo a Milanofiori 2000 s.r.l., con sede in via G.B. Piranesi n. 10.
      </p>
    </div>
  </section>
}