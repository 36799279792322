import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserManager} from "../services/auth/oidc_auth";

export function Callback() {
    console.log("Callback")
    const navigate = useNavigate()
    useEffect(() => {
        let ignore = false;
        const handle = async () => {
            if (ignore) {
                return;
            }
            const last_signinRedirect_json = localStorage.getItem("last_signinRedirect")
            if (last_signinRedirect_json) {
                // const last_signinRedirect = JSON.parse(last_signinRedirect_json)
                // setUserManagerAuthority(last_signinRedirect.idp.domain)
                
                try {
                    // await userManager.signinRedirectCallback()
                    const user = await getUserManager().signinCallback()
                    console.log(user)
                    localStorage.removeItem("last_signinRedirect")
                    navigate("/")
                } catch (error) {
                    await getUserManager().removeUser()
                    localStorage.removeItem("last_signinRedirect")
                    navigate("/login")
                }

            } else {
                navigate("/login")
            }
        };
        handle()
        return () => {
            ignore = true;
        };
        /* Prendo le informazioni che sono state salvate in local storage prima di fare redirect */
    }, [navigate]);
    return <p>Caricamento...</p>;
}