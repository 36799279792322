import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Targa } from "../../models/model";
import { getJwtToken } from "../auth/auth";
import { apiEndpoint, fetchJson, useMutationWithAuth } from "./config";
export function useTarghe() {
  const path = "/profilo/targhe";
  return useQuery<Targa[]>({
    queryKey: ["targhe"],
    queryFn: async (context) => {
      const token = await getJwtToken();

      return fetch(apiEndpoint + path, {
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `bearer ${token}` as string,
        }),
      })
        .then((res) => res.json() as Promise<Targa[]>)
        .then((list) => {
          return list.sort((a, b) => a.targa.localeCompare(b.targa));
        });
    },
  });
}

const createTarga = (accessToken: string, targa: CreateTargaReqFields["targa"], nota: CreateTargaReqFields["nota"]) =>
  fetchJson<{ id: number }>(apiEndpoint + `/profilo/targhe`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      targa,
      nota,
    }),
  });
type CreateTargaReqFields = { targa: string; nota: string };
type UpdateTargaReqFields = { targa: Targa["targa"]; nota: Targa["nota"] };

export function useCreateTarga() {
  const queryClient = useQueryClient();
  return useMutationWithAuth<{ id: number }, Error, CreateTargaReqFields>({
    mutationKey: ["targhe"],
    mutationFn: async ({ targa, nota }) => {
      const token = await getJwtToken();
      if (!token) {
        throw new Error("Errore durante la creazione della targa.. no token");
      }
      const one = await createTarga(token, targa, nota);
      if (!one) {
        throw new Error("Errore durante la creazione della targa");
      }
      return one;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["targhe"] });
    },
  });
}
const updateTarga = (id: Targa["id"], accessToken: string, targa: UpdateTargaReqFields["targa"], nota: UpdateTargaReqFields["nota"]) =>
  fetchJson<{ id: string }>(apiEndpoint + `/profilo/targhe/${id}/edit`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      targa,
      nota,
    }),
  });
export function useUpdateTarga() {
  const queryClient = useQueryClient();
  return useMutationWithAuth<{ id: string }, Error, UpdateTargaReqFields & { id: Targa["id"] }>({
    mutationKey: ["targa"],
    mutationFn: async ({ targa, nota, id }) => {
      const token = await getJwtToken();
      if (!token) {
        throw new Error("Errore durante l'aggiornamento della targa.. no token");
      }
      const one = await updateTarga(id, token, targa, nota);
      if (!one) {
        throw new Error("Errore durante l'aggiornamento della targa");
      }
      return one;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["targhe"] });
    },
  });
}

const enableTarga = (id: Targa["id"], accessToken: string, targa_abilitata: Targa["targa_abilitata"]) =>
  fetchJson<boolean>(apiEndpoint + `/profilo/targhe/${id}/switch`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      targa_abilitata,
    }),
  });
export function useEnableTarga() {
  const queryClient = useQueryClient();
  return useMutationWithAuth<Targa["id"], Error, Pick<Targa, "id">>({
    mutationKey: ["targa"],
    mutationFn: async ({ id }) => {
      const token = await getJwtToken();
      if (!token) {
        throw new Error("Errore durante l'attivazione della targa.. no token");
      }
      const res = await enableTarga(id, token, true);
      if (!res) {
        throw new Error("Errore durante l'attivazione della targa");
      }
      return id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["targhe"] });
    },
  });
}
export function useDisableTarga() {
  const queryClient = useQueryClient();
  return useMutationWithAuth<Targa["id"], Error, Pick<Targa, "id">>({
    mutationKey: ["targa"],
    mutationFn: async ({ id }) => {
      const token = await getJwtToken();
      if (!token) {
        throw new Error("Errore durante la disattivazione della targa.. no token");
      }
      const res = await enableTarga(id, token, false);
      if (!res) {
        throw new Error("Errore durante la disattivazione della targa");
      }
      return id;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["targhe"] });
    },
  });
}
