import { UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

export type IDP = { domain: string; cognito_idp_idefier: string; }
export type OIDCUSerSession = any // Todo: Define OIDC User Session
export type OIDCCognitoUser = any // Todo: Define OIDC User Session
export type OIDCCognitoUserAttribute = any // Todo: Define OIDC User Session
export const idps = [
  {
    domain: 'accenture.com',
    cognito_idp_idefier: 'accenture-azure',
    // authority: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_yOCNYi3Dz',
  },
  {
    domain: 'ds.dev.accenture.com',
    cognito_idp_idefier: 'accenture-azure',
    // authority: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_yOCNYi3Dz',
  }
];
const oidcConfig: UserManagerSettings = {
  authority: process.env.REACT_APP_COGNITO_AUTHORITY!,
  client_id: process.env.REACT_APP_CLENT_ID!,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL!,
  response_type: 'code',
  scope: 'openid profile email',
  post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URL!,
  userStore: new WebStorageStateStore({
    store: window.localStorage,
  }),
  response_mode: "query",
  automaticSilentRenew: true,
};
let resolve: (payload: any) => void;
const userLoaded = new Promise((res) => {
  resolve = res;
});
const userManager = new UserManager(oidcConfig);
userManager.events.addUserLoaded((user) => {
  resolve(user);
});



/* Getter */
export function getUserManager() {
  // if (!userManager) {
  //   userManager = 
  // }

  return userManager;
}

export async function getSession() {
  // const loaded = await userLoaded;
  // console.log(loaded)
  const user = await getUserManager().getUser();
  return user;
}
export async function getUsername() {
  const user = await getUserManager().getUser()
  return user?.profile.email;
}
export async function getCognitoUserWithSession() {
  return await getUserManager().getUser();

}
export async function getUserAttributes() {
  return (await getUserManager().getUser())?.profile;
}
export async function logOutUser() {
  try {
    await getUserManager().removeUser();
    await getUserManager().revokeTokens();
    await getUserManager().signoutRedirect();
  } catch (err) {
    console.error(err);
  }
}