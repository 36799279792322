import { useUtente } from "../../services/api/utente";
import { PageTitle } from "../shared/PageTitle";

export default function Aiuto() {

  const {
    data: utente,
    error: isUtenteError,
    isLoading: isUtenteLoading,
  } = useUtente()
  return <section>
    <PageTitle show_back_button_on_mobile={true} title="Aiuto" />

    <div className="mt-7">
      <p>
        Caro/a  {utente?.nome} {utente?.cognome}
        <br />
        <br />
        se hai domande o hai bisogno di assistenza, non esitare a contattarci <br /> all’indirizzo <a className="font-bold underline" href="mailto:gestione-park@comprensoriomfnord.it">gestione-park@comprensoriomfnord.it</a>
        <br />
        <br />
        Speriamo che tu possa godere appieno dei nostri servizi di parcheggio!
        <br />
        <br />
        Cordiali saluti,
        <br />
        Il team di Parcheggi Milanofiori Nord
        <br />
        <br />
        <br />
        Versione {process.env.REACT_APP_VERSION}
        </p>
    </div>
  </section>
}