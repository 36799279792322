import { useEffect, useState } from "react"

export default function ConfirmationModal(props: {
  title?: string,
  text?: string,
  confirmationText?: string,
  deletionText?: string,
  onConfirm: () => void
  onCancel: () => void
}) {
  const [title, setTitle] = useState<string>("")
  const [text, setText] = useState<string>("")
  const [confirmationText, setconfirmationText] = useState<string>("")
  const [deletionText, setDeletionText] = useState<string>("")
  useEffect(() => {
    if (!props.title) {
      setTitle("Sei sicuro?")
    } else {
      setTitle(props.title)

    }
    if (!props.text) {
      setText("Vuoi confermare l'operazione?")
    } else {
      setText(props.text)

    }
    if (!props.confirmationText) {
      setconfirmationText("Conferma")
    } else {
      setconfirmationText(props.confirmationText)

    }
    if (!props.deletionText) {
      setDeletionText("Annulla")
    } else {
      setDeletionText(props.deletionText)

    }
  }, [props.confirmationText, props.deletionText, props.text, props.title])
  return <div className="confirmation-modal-container">

    <ol>
      <li>
        <img src={`${process.env.PUBLIC_URL}/Milanofiori-question-mark-white.png`} alt={'Milanofiori-question-mark-white'} />
      </li>
      <li className="mt-10">
        <h1>{title}</h1>
      </li>
      <li className="mt-4">
        <p className="prenotazione-dialog-informations">{text}</p>

      </li>
      <li>
        <div className="parcheggio-milano-fiori-nord-floating-button">
          <button onClick={() => { props.onConfirm() }} className={`big-button button-third  white-version`}>
            <div className={`parcheggio-milano-fiori-nord-floating-button-text-container`}>
              <strong>{confirmationText}</strong>
              <div
                className={`parcheggio-milano-fiori-nord-floating-button-text-line parcheggio-milano-fiori-nord-floating-button-text-line-white-version`}></div>
            </div>
          </button>
        </div>
        <div className="parcheggio-milano-fiori-nord-floating-button-2">
          <button onClick={() => { props.onCancel() }} className={`big-button button-third`}>
            <div className={`parcheggio-milano-fiori-nord-floating-button-text-container`}>
              <strong>{deletionText}</strong>
              <div
                className={`parcheggio-milano-fiori-nord-floating-button-text-line`}></div>
            </div>
          </button>
        </div>
      </li>
    </ol>
  </div>
}
