import { TextField } from "@mui/material";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SnackBar } from "../../models/SnackBar.model";
import { userPool } from "../../services/auth/auth";
import { MyCustomSnackBar } from "../shared/MyCustomSnackBar";
import { RequestPendingButtonSpinner } from "../shared/RequestPendingButtonSpinner";

export function ForgotPassword() {
    const [resetPasswordFlowStatus, setResetPasswordFlowStatus] = useState<'INSERT_EMAIL' | 'INSERT_CODE_AND_PASSWORD'>('INSERT_EMAIL')
    const [username, setUsername] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
    const [user, setUser] = useState(new CognitoUser({
        Pool: userPool,
        Username: username,
    }))
    const [requestPending, setRequestPending] = useState(false);
    const [authError, setAuthError] = useState<string | undefined>(undefined);
    const handleResetPassword = (event: any) => {
        event.preventDefault();
        setRequestPending(true)
        //inputVerificationCode: function (data) {
        user.confirmPassword(verificationCode, newPassword, {
            onSuccess() {
                setRequestPending(false)
                setAuthError(undefined)
                setSnackBar({
                    opened: true,
                    message: "Password Modificata Con successo",
                    shoudNavigateBackOnClose: true
                })

            },
            onFailure(err) {
                setRequestPending(false)
                console.log('Password not confirmed!');
                setAuthError(err?.message || `Auth Error (${err?.name || 'Unknown'})`)
                console.log(err)
            },
        });
        //}
    }
    const handleSendCode = (event: any) => {
        event.preventDefault();
        user.forgotPassword({
            onSuccess: function (data) {
                setRequestPending(false)
                // successfully initiated reset password request
                console.log('CodeDeliveryData from forgotPassword: ' + data);
            },
            onFailure: function (err) {
                setRequestPending(false)
                setAuthError(err?.message || `Auth Error (${err?.name || 'Unknown'})`)
                console.log(err)
            },
            //Optional automatic callback
            inputVerificationCode: function (data) {
                setResetPasswordFlowStatus('INSERT_CODE_AND_PASSWORD')
            },
        });
    }
    useEffect(() => {
        setUser(new CognitoUser({
            Pool: userPool,
            Username: username,
        }))
    }, [username])
    const [snackBar, setSnackBar] = useState<SnackBar | undefined>(undefined);

    const navigate = useNavigate()
    return (
        <div className="h-screen w-screen grid grid-cols-1 md:grid-cols-2">
            <div className="hidden md:block md:w-50 h-full parcheggio-milano-fiori-nord-bg-primary">
                <div className="flex flex-col m-auto justify-center h-full max-w-[80vw] md:max-w-[50%]">
                    <img src={`${process.env.PUBLIC_URL}/Milanofiori-tagline-Main-logo-H-N-white.png`} alt="" />
                </div>
            </div>

            {resetPasswordFlowStatus === "INSERT_EMAIL" && (

                <div className="w-100 md:w-50 h-full">
                    <div className="flex flex-col m-auto justify-center h-full login-container max-w-[80vw] md:max-w-[50%]">
                        <div className="text-big !font-bold">Resetta la password</div>
                        <div className="subtitle">Inserisci la tua mail così che possiamo inviarti un codice di verifica per reimpostarla.</div>
                        <form className="flex flex-col" onSubmit={(event) => handleSendCode(event)} action="">

                            <TextField value={username} disabled={requestPending} onChange={(event) => {
                                const trimmedValue = event.currentTarget.value.replace(/\s/g, '')
                                setUsername(trimmedValue)
                            }} label="Email" variant="outlined" />
                            {requestPending && (
                                <RequestPendingButtonSpinner />
                            )}
                            {!requestPending && (
                                <div className="flex justify-end">

                                    <button type="submit" disabled={!username || username === ''} className="big-button button-third w-100 text-big !font-bold text-white">Invia</button>
                                </div>
                            )}
                        </form>
                        <div className="flex-col">
                            <div className="text-normal !mt-2">
                                Hai già un codice valido? <strong onClick={() => {
                                    setResetPasswordFlowStatus("INSERT_CODE_AND_PASSWORD")
                                }} className="underline">Vai alla reset password</strong>
                            </div>
                        </div>
                        <div>
                            {authError &&
                                <div className="text-normal text-red-500 font-bold">
                                    {authError}
                                </div>
                            }

                        </div>
                    </div>
                    <div className="login-mobile-image md:hidden">
                        <img className="header-image" src={`${process.env.PUBLIC_URL}/Milanofiori-tagline-Main-logo-H-N.png`} alt="Milanofiori-tagline-Main-logo-H-N" />
                    </div>
                </div>

            )}
            {resetPasswordFlowStatus === "INSERT_CODE_AND_PASSWORD" && (
                <div className="w-100 md:w-50 h-full">
                    <div className="flex flex-col m-auto justify-center h-full login-container max-w-[80vw] md:max-w-[50%]">
                        <div className="text-big !font-bold">Resetta la password</div>
                        <div className="subtitle">Controlla la tua mail in cui abbiamo inviato un codice da inserire qui sotto per effettuare il reset della tua password.</div>
                        <form className="flex flex-col" onSubmit={(event) => handleResetPassword(event)} action="">
                            <TextField disabled={requestPending} value={username} onChange={(event) => {
                                const trimmedValue = event.currentTarget.value.replace(/\s/g, '')
                                setUsername(trimmedValue)
                            }} label="Email" variant="outlined" />
                            <TextField disabled={requestPending} value={verificationCode} onChange={(event) => {
                                const trimmedValue = event.currentTarget.value.replace(/\s/g, '')
                                setVerificationCode(trimmedValue)
                            }} label="Codice" variant="outlined" />
                            <TextField disabled={requestPending} value={newPassword} onChange={(event) => {
                                const trimmedValue = event.currentTarget.value.replace(/\s/g, '')
                                setNewPassword(trimmedValue)
                            }} label="Nuova Password" variant="outlined" />
                            <TextField disabled={requestPending} value={newPasswordConfirmation} onChange={(event) => {
                                const trimmedValue = event.currentTarget.value.replace(/\s/g, '')
                                setNewPasswordConfirmation(trimmedValue)
                            }} label="Nuova Password" variant="outlined" />
                            {requestPending && (
                                <RequestPendingButtonSpinner />
                            )}
                            {!requestPending && (
                                <button type="submit" disabled={!username || username === '' || !newPassword || newPassword === '' || !verificationCode || verificationCode === ''} className="big-button button-third w-100 text-big !font-bold text-white">Salva</button>
                            )}
                        </form>
                        <div>
                            {authError &&
                                <div className="text-normal text-red-500 font-bold">
                                    {authError}
                                </div>
                            }
                        </div>
                        <div className="text-normal font-bold">
                            La password deve:
                            <ul>
                                <li>Essere lunga almeno 8 caratteri.</li>
                                <li>Avere almeno uno dei seguenti criteri:
                                    <div>Contenere almeno un caratere numerico</div>
                                    <div>Contenere almeno un carattere speciale</div>
                                    <div>Contenere almeno un carattere maiuscolo</div>
                                    <div>Contenere almeno un carattere minuscolo</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="login-mobile-image md:hidden">
                        <img className="header-image" src={`${process.env.PUBLIC_URL}/Milanofiori-tagline-Main-logo-H-N.png`} alt="Milanofiori-tagline-Main-logo-H-N" />
                    </div>
                </div>
            )}
            {
                snackBar && snackBar.opened &&
                <MyCustomSnackBar
                    message={snackBar.message}
                    style={{ backgroundColor: snackBar.status === 'NEGATIVE' ? 'red' : '#185a7d' }}
                    onClose={() => {
                        const shoudNavigateBackOnClose = snackBar.shoudNavigateBackOnClose
                        setSnackBar({
                            message: '',
                            opened: false,
                            shoudNavigateBackOnClose: undefined
                        });
                        if (shoudNavigateBackOnClose) {
                            navigate('../')
                        }
                    }} />
            }
            {/*  )} */}
        </div >
    )
}