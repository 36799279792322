import InfoIcon from "@mui/icons-material/Info";
import { Backdrop, Box, CircularProgress, Modal, Skeleton } from "@mui/material";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Prenotazione } from "../../models/model";
import { useDeleteablePrenotazione, useDeletePrenotazione, usePrenotazioniFuture } from "../../services/api/prenotazioni";
import FixedButtonPrenotazione from "../FixedButtonPrenotazione";
import ConfirmationModal from "../shared/ConfirmationModal";
import ErroreDialog from "../shared/ErroreDialog";
import ModalMap from "./ModalMap";
import FixedButtonDuplicaPrenotazione from "../FixedButtonDuplicaPrenotazione";
import { useUtente } from "../../services/api/utente";

export default function SingolaPrenotazione() {
  const navigate = useNavigate();

  const { id } = useParams();
  if (!id) {
    navigate("/");
  }
  if (!parseInt(id as string)) {
    navigate("/");
  }
  const { data: prenotazioniFuture, isLoading: isPrenotazioniFutureLoading } = usePrenotazioniFuture();
  const {
    mutateAsync: deletePrenotazione,
    isPending: isDeletionPrenotazionePending,
    error: deletionPrenotazioneError,
  } = useDeletePrenotazione(parseInt(id as string));
  const {
    data: utente,
    error: isUtenteError,
    isLoading: isUtenteLoading,
  } = useUtente();
  const { isLoading: isDeleteablePending, data: deleteableData, error: deleteableError } = useDeleteablePrenotazione(parseInt(id as string));
  const [confirmationModalOpenStatus, setConfirmationModalOpenStatus] = useState<boolean>(false);
  const [confirmedModalOpenStatus, setConfirmedModalOpenStatus] = useState<boolean>(false);
  const [openLegenda, setOpenLegenda] = useState<boolean>(false);

  const prenotazione = useMemo(() => prenotazioniFuture?.find((el) => el.id === parseInt(id as string)), [id, prenotazioniFuture]);
  let ora_apertura = undefined;
  let ora_chiusura = undefined;

  let ora_ingresso = undefined;
  let ora_uscita = undefined;
  let nomeGiorno = undefined;
  let nomeMese = undefined;
  if (prenotazione) {
    ora_apertura = format(prenotazione.parcheggio.ora_apertura, "HH:mm");
    ora_chiusura = format(prenotazione.parcheggio.ora_chiusura, "HH:mm");

    ora_ingresso = format(prenotazione.ora_ingresso, "HH:mm");
    ora_uscita = format(prenotazione.ora_uscita, "HH:mm");
    nomeGiorno = format(prenotazione?.data, "EEEE", { locale: it });
    nomeGiorno = nomeGiorno.charAt(0).toUpperCase() + nomeGiorno.substring(1, nomeGiorno.length);

    nomeMese = format(prenotazione?.data, "MMMM", { locale: it });
    nomeMese = nomeMese.charAt(0).toUpperCase() + nomeMese.substring(1, nomeMese.length);
  }
  const handleDeleteParcheggio = useCallback(() => {
    setConfirmationModalOpenStatus(false);
    setConfirmedModalOpenStatus(false);
    deletePrenotazione(parseInt(id as string))
      .then(() => {
        navigate("..");
      })
      .catch(() => {});
  }, [deletePrenotazione, navigate, id]);

  return (
    <section>
      {isDeletionPrenotazionePending && (
        <Backdrop sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })} open={true}>
          <div className="flex flex-col items-center">
            <CircularProgress color="inherit" />
            <p className="mt-4">L'operazione potrebbe richiedere qualche secondo... Attendere prego.</p>
          </div>
        </Backdrop>
      )}
      <h1>Prenotazione</h1>
      <div className="mt-7">
        {isPrenotazioniFutureLoading && <Skeleton height={100} />}
        {!isPrenotazioniFutureLoading && (
          <>
            {!prenotazione && <p className="no-data">La seguente prenotazione non è stata trovata!</p>}
            {confirmedModalOpenStatus && (
              <div className="prenotazione-dialog-container">
                <ol>
                  <li>
                    <img src={`${process.env.PUBLIC_URL}/confirmed-icon.svg`} alt="confirmed" />
                  </li>
                  <li className="mt-10">
                    <h1>Perfetto!</h1>
                  </li>
                  <li className="mt-4">
                    <p className="prenotazione-dialog-informations">
                      La tua prenotazione per il giorno:
                      {prenotazione && (
                        <b>
                          <h3>{format(prenotazione.data, "dd-MM-yyy")}</h3>{" "}
                        </b>
                      )}
                      è stata cancellata!
                    </p>
                  </li>
                  <li>
                    <FixedButtonPrenotazione
                      onClick={() => {
                        navigate("/");
                      }}
                      version="white"
                      disabled={false}
                      text={"Torna alle prenotazioni"}
                    />
                  </li>
                </ol>
              </div>
            )}
            {prenotazione && nomeGiorno && (
              <>
                <ul className="prenotazione-futura-detail">
                  <li>
                    <li className="!text-start">
                      <h3>
                        {" "}
                        {nomeMese} - {nomeGiorno} {format(prenotazione?.data, "dd")}
                      </h3>
                    </li>
                  </li>
                  <li>
                    <p>
                      <strong>Parcheggio:</strong>
                      {prenotazione.lotto?.nome_parcheggio}
                    </p>
                  </li>
                  {prenotazione.lotto.scelta_posto === true && (
                    <li>
                      <p>
                        <strong>Posto:</strong>
                        {prenotazione.posti_codice}
                      </p>
                    </li>
                  )}
                  <li>
                    <p>
                      <strong>Fascia Oraria:</strong>
                      {ora_ingresso} - {ora_uscita}
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Orari Parcheggio:</strong>
                      {ora_apertura} - {ora_chiusura}
                    </p>
                  </li>
                  {prenotazione.entrata && (
                    <li>
                      <p>
                        <strong>Orario di entrata:</strong>
                        {format(prenotazione.entrata, "HH:mm")}
                      </p>
                    </li>
                  )}
                  {prenotazione?.prenotata_da_sub && !prenotazione?.autore && (
                    <li>
                      <p>Questa prenotazione è stata fatta da un utente master.</p>
                    </li>
                  )}
                  {prenotazione?.prenotata_da_sub && prenotazione?.autore && (
                    <li>
                      <p>
                        <strong>Prenotato da:</strong>
                        {prenotazione.autore?.nome} {prenotazione.autore?.cognome}
                      </p>
                    </li>
                  )}
                </ul>
                {prenotazione.lotto.mappa_path && (
                  <>
                    {openLegenda === false && (
                      <div className="relative flex justify-between text-blue-500 underline">
                        <button
                          onClick={() => {
                            setOpenLegenda(true);
                          }}
                          className="legenda-button"
                        >
                          <InfoIcon />
                          <strong>Visualizza legenda</strong>
                        </button>
                      </div>
                    )}
                    <ShowLegenda
                      open={openLegenda}
                      handleClose={() => {
                        setOpenLegenda(false);
                      }}
                    />
                    <ModalMap
                      selected={() => {}}
                      posti_codice={prenotazione.posti_codice}
                      allPlaces={[]}
                      availablePlaces={[]}
                      url={`${prenotazione.lotto.mappa_path}`}
                      onClose={() => {
                        setOpenLegenda(true);
                      }}
                    />
                  </>
                )}
                {confirmationModalOpenStatus && (
                  <ConfirmationModal
                    title="Conferma la l'operazione"
                    text={`Sei sicuro di voler cancellare la prenotazione per il giorno ${nomeMese} - ${nomeGiorno} ${format(prenotazione?.data, "dd")}?`}
                    onConfirm={() => {
                      handleDeleteParcheggio();
                    }}
                    onCancel={() => {
                      setConfirmationModalOpenStatus(false);
                    }}
                  />
                )}
              </>
            )}
          </>
        )}
        {!isUtenteLoading && !isUtenteError && utente && (
          <>
            {utente.abilitato_duplica_prenotazione && prenotazione?.id && !prenotazione?.prenotata_da_sub && (
              <FixedButtonDuplicaPrenotazione
                key="button-duplica-prenotazione"
                onClick={() => navigate(`/prenotazioni/${prenotazione.id}/duplica`)}
                version="white"
                disabled={false}
                text="Duplica prenotazione in altre date"
              />
            )}
          </>
        )}
        <FixedButtonPrenotazione
          version="red"
          disabled={isDeleteablePending || !!deleteableError}
          text="Cancella Prenotazione"
          disabledHint={deleteableError || undefined}
          onClick={() => {
            setConfirmationModalOpenStatus(true);
          }}
        />
        {!!deletionPrenotazioneError && (
          <ErroreDialog errorMessage={(deletionPrenotazioneError as any)?.message || "Errore durante la cancellazione della prenotazione"} />
        )}
      </div>
    </section>
  );
}
export function ShowLegenda(props: { open: boolean; handleClose: () => void }) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    bgcolor: "background.paper",
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal open={props.open} onClose={props.handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" keepMounted>
      <Box sx={style} className="w-[80vw] md:w-auto">
        <h2>Utilizzo della mappa per la visualizzazione posto</h2>
        <p className="mt-5">La mappa ti permette di visualizzare il posto auto in maniera visuale.</p>
        <p className="mt-2">
          Il posto associato alla vostra prenotazione è di colore <strong style={{ color: "#185a7d" }}>azzurro</strong>.
        </p>
        <p className="mt-2">
          Per interagire con la mappa:
          <ul>
            <li>
              - Da <strong>dispositivi desktop</strong> è possibile ingradire la mappa utilizzando il "doppio click" o attraverso la rotella del mouse. E' anche
              possibile muovere la mappa tenendo prenuto il tasto sinistro del mouse e trascinandola.
            </li>
            <li>
              - Da <strong>dispositivi mobile</strong> è possibile ingrandire la mappa utilizzando il "doppio tap" o ingrandendola come una normale immagine. E'
              anche possibile muovere la mappa trascinandola.
            </li>
          </ul>
        </p>
        <div className="flex justify-end mt-5">
          <button className="big-button button-third blue-version !w-fit" onClick={props.handleClose}>
            Chiudi
          </button>
        </div>
      </Box>
    </Modal>
  );
}
