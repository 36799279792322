import { Snackbar, SnackbarContent, SnackbarProps } from "@mui/material"

export function MyCustomSnackBar(props: SnackbarProps) {
    const message = props.message
    /* const props.autoHideDuration = props.autoHideDuration || 2000; */
    return (
        <Snackbar

            style={{
                backgroundColor: props?.style?.backgroundColor || '#185a7d',
            }
            }
            {...props}
            open={true}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={props.autoHideDuration || 2000}

        >
            <SnackbarContent
                style={{
                    backgroundColor: props?.style?.backgroundColor || '#185a7d',
                }}
                message={props.message} />
        </Snackbar>
    )
}