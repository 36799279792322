import { CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
export type UserSession = CognitoUserSession
export const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID as string,
    ClientId: process.env.REACT_APP_CLENT_ID as string,
}

export const userPool = new CognitoUserPool(poolData);
let session: CognitoUserSession;
let cognitoUser: CognitoUser | null = userPool.getCurrentUser()
export async function getSession(): Promise<CognitoUserSession | undefined> {
    const cognitoUser = userPool.getCurrentUser();
    
    if (!cognitoUser) {
        return undefined;
    }
    if (session && session !== null && session.isValid()) {
        return session;
    }
    return new Promise((resolve, reject) => cognitoUser.getSession((err: unknown, cbSession: CognitoUserSession) => {
        if (err) {
            reject(err)
        } else {
            cognitoUser.setSignInUserSession(cbSession)
            session = cbSession;
            resolve(cbSession)
        }
    }));
}
export function setCognitoUser(user: CognitoUser | null): void {
    cognitoUser = user
}
export function getUsername(): string {

    /* const cognitoUser: CognitoUser | null = userPool.getCurrentUser() */

    return cognitoUser?.getUsername() || '?'
}

export async function getCognitoUserWithSession(): Promise<CognitoUser | null> {
    if (cognitoUser && cognitoUser.getSignInUserSession()) {
        return cognitoUser
    } else {
        if (cognitoUser && !cognitoUser.getSignInUserSession()) {
            const session = await getSession()
            if (session) {
                cognitoUser.setSignInUserSession(session)
                return cognitoUser
            }
        }
    }
    return null
}

export async function getUserAttributes(): Promise<CognitoUserAttribute[]> {
    return new Promise(async (resolve, reject) => {
        let attrs: CognitoUserAttribute[] = [];
        const userWithSession = await getCognitoUserWithSession()
        if (userWithSession) {
            userWithSession.getUserAttributes((err, attributes) => {
                if (err) {
                    reject(err)
                } else {
                    resolve(attributes || [])
                }

            })
            return attrs || []
        }
    });
}
export async function logOutUser(): Promise<void> {

    const cognitoUser1 = userPool.getCurrentUser();
    return new Promise((resolve, reject) => {
        try {
            cognitoUser1?.signOut(() => {
                resolve();
                cognitoUser = null;
            });
        } catch (err) {
            reject(err)
        }
    })


}
