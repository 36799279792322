import { useQuery } from "@tanstack/react-query"
import { format, parse } from "date-fns"
import { Parcheggio, ParcheggioDateString } from "../../models/model"
import { getJwtToken } from "../auth/auth"
import { apiEndpoint } from "./config"

export function useParcheggiDisponibili( data?: Date, fasciaOraria?: "ora" | "mattina" | "pomeriggio" | "giorno", oraIngresso?: Date, oraUscita?: Date,) {
  const dateFormatted = data ? format(data, 'yyy-MM-dd') : undefined
  let queryParams = `?data=${dateFormatted}&fascia-oraria=${fasciaOraria}`
  let enabled = !!data && !!fasciaOraria;
  if (fasciaOraria === 'ora') {
    const fromFormatted = oraIngresso ? format(oraIngresso, 'HH:mm') : undefined
    const toFormatted = oraUscita ? format(oraUscita, 'HH:mm') : undefined
    queryParams = queryParams + `&ora-ingresso=${fromFormatted}&ora-uscita=${toFormatted}`
    enabled = enabled && !!oraIngresso && !!oraUscita
  }
  const path = `/parcheggi-disponibili${queryParams}`
  return useQuery<Parcheggio[]>({
    queryKey: ['parcheggi-disponibili', path],
    enabled,
    gcTime: 100,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    queryFn: async (context) => {
      const token = await getJwtToken()
      return fetch(apiEndpoint + path, {
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `bearer ${token}` as string,
        }),
      })
        .then(res => { return res.json() as Promise<ParcheggioDateString[]> })
        .then(list => {
          return list.map((a) => {
            const ora_apertura = parse(a.parcheggio.ora_apertura, 'HH:mm:ss', data!)
            const ora_chiusura = parse(a.parcheggio.ora_chiusura, 'HH:mm:ss', data!)
            return {
              ...a,
              parcheggio: {
                ...a.parcheggio,
                ora_apertura,
                ora_chiusura
              }
              
            }
          });
        })
    }
  })
}