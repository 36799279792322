import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { Alert, Backdrop, CircularProgress } from "@mui/material";
import { isSameDay, addDays, isAfter } from "date-fns";
import it from "date-fns/locale/it";
import Container from "@mui/material/Container";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { itIT } from "@mui/x-date-pickers/locales";
import { usePrenotazioniFuture, useAttivaTargaPrenotazioniFuture } from "../../services/api/prenotazioni";
import { useUtente } from "../../services/api/utente";
import { useTarghe } from "../../services/api/targhe";
import FixedButtonPrenotazione from "../FixedButtonPrenotazione";
import ErroreDialog from "../shared/ErroreDialog";

export default function AttivaTargaPrenotazioniFuture() {
  const navigate = useNavigate();
  const { id } = useParams();
  const intId = parseInt(id as string);

  const { data: utente, error: isUtenteError, isLoading: isUtenteLoading } = useUtente();
  const { data: targhe, error: isTargheError, isLoading: isTargheLoading } = useTarghe();
  const { data: prenotazioniFuture, error: isPrenotazioniFutureError, isLoading: isPrenotazioniFutureLoading } = usePrenotazioniFuture();
  
  const targaAttuale = useMemo(() => targhe?.find((el) => el.id === intId), [intId, targhe]);
  const { mutate: attivaTargaPrenotazioniFuture, isPending, isSuccess, isError, error, data } = useAttivaTargaPrenotazioniFuture(intId);

  const minDate = useMemo(() => {
    const firstReservationDate = prenotazioniFuture?.[0]?.data ? new Date(prenotazioniFuture[0].data) : addDays(new Date(), 1);
    return isSameDay(firstReservationDate, new Date()) ? addDays(firstReservationDate, 1) : firstReservationDate;
  }, [prenotazioniFuture]);
  const [startDate, setStartDate] = useState<Date>(minDate);

  const maxDate = useMemo(() => {
    const lastReservationDate = prenotazioniFuture?.[prenotazioniFuture.length - 1]?.data
      ? new Date(prenotazioniFuture[prenotazioniFuture.length - 1].data)
      : new Date(startDate);
      return isSameDay(lastReservationDate, new Date()) ? addDays(lastReservationDate, 1) : lastReservationDate;
  }, [prenotazioniFuture, startDate]);
  const [endDate, setEndDate] = useState<Date>(maxDate);

  useEffect(() => {
    if (!isTargheLoading && !targhe && !isPrenotazioniFutureLoading && !prenotazioniFuture) {
      navigate("/");
    }
  }, [isPrenotazioniFutureLoading, isTargheLoading, prenotazioniFuture, targhe, navigate]);

  useEffect(() => {
    setStartDate(minDate);
  }, [minDate]);
  useEffect(() => {
    setEndDate(maxDate);
  }, [maxDate]);

  const isConfirmDisabled = useMemo(() => {
    return !startDate || !endDate || isAfter(startDate, endDate) || isPending || prenotazioniFuture?.length === 0;
  }, [startDate, endDate, isPending, prenotazioniFuture]);

  const handleConfirmClick = () => {
    if (!startDate || !endDate) return;

    attivaTargaPrenotazioniFuture(
      { dataInizio: startDate, dataFine: endDate },
      {
        onError: (error) => {
          console.error("Errore durante l'aggiornamento delle prenotazioni:", error.message);
        },
      }
    );
  };

  if (isPrenotazioniFutureLoading || isTargheLoading) {
    return (
      <div className="absolute-centered">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {isPending && (
        <Backdrop sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })} open={true}>
          <div className="flex flex-col items-center">
            <CircularProgress color="inherit" />
            <p className="mt-4">L'operazione potrebbe richiedere qualche secondo... Attendere prego.</p>
          </div>
        </Backdrop>
      )}
      {!isTargheLoading && targhe && targaAttuale && (
        <section>
          <div className="flex flex-col md:flex-row justify-start md:justify-between items-start md:items-center text-start">
            <div className="mt-4 page-title-container">
              <h1>Aggiorna le tue prenotazioni attive future</h1>
            </div>
            <div className="mt-4">
              <h3>Targa {targaAttuale.targa}</h3>
            </div>
          </div>

          {!isPrenotazioniFutureLoading && prenotazioniFuture && (
            <>
              {prenotazioniFuture.length > 0 && (
                <>
                  <div className="flex flex-col md:flex-row justify-start md:justify-between items-start md:items-center text-start mt-4">
                    {!isUtenteLoading && !isUtenteError && utente && (
                      <LocalizationProvider
                        localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                        adapterLocale={it}
                        dateAdapter={AdapterDateFns}
                      >
                        <Container className="!p-0 mt-4">
                          <h3 className="!text-start">da</h3>
                          <DatePicker
                            label="Data inizio"
                            className="w-full !mt-4"
                            value={startDate}
                            format="dd/MM/yyyy"
                            shouldDisableDate={(date) => isSameDay(date, new Date())}
                            onChange={(e) => setStartDate(e ? new Date(e.getTime()) : startDate)}
                            minDate={minDate}
                            maxDate={maxDate}
                          />
                        </Container>
                        <Container className="!p-0 mt-4">
                          <h3 className="!text-start">a</h3>
                          <DatePicker
                            label="Data fine"
                            className="w-full !mt-4"
                            value={endDate}
                            format="dd/MM/yyyy"
                            shouldDisableDate={(date) => isSameDay(date, addDays(maxDate, 1))}
                            onChange={(e) => setEndDate(e ? new Date(e.getTime()) : endDate)}
                            minDate={startDate}
                            maxDate={maxDate}
                          />
                        </Container>
                      </LocalizationProvider>
                    )}
                  </div>
                  <FixedButtonPrenotazione onClick={handleConfirmClick} version="blue" disabled={isConfirmDisabled} text={"Conferma"} />
                </>
              )}
              {prenotazioniFuture.length === 0 && (
                <>
                  <Alert severity="warning" className="my-4">
                    Non è necessario effettuare alcun aggiornamento in quanto non risultano prenotazioni attive effettuate.
                  </Alert>
                  <FixedButtonPrenotazione
                    onClick={() => {
                      navigate(`/prenotazioni`);
                    }}
                    version="white"
                    text={"Torna alle prenotazioni"}
                  />
                </>
              )}
            </>
          )}
        </section>
      )}

      {isError && <ErroreDialog errorMessage={(error?.message as string) || "Errore"} />}

      {isSuccess && (
        <div className="prenotazione-dialog-container">
          <ol>
            <li>
              <img src={`${process.env.PUBLIC_URL}/${"confirmed"}-icon.svg`} alt={"confirmed"} />
            </li>
            <li className="mt-10">
              <h1>Perfetto!</h1>
            </li>
            <li className="mt-4">
              <h3 className="prenotazione-dialog-informations">{data.message}</h3>
            </li>
            {data.prenotazioni.length > 0 && (
              <li className="mt-4">
                <p className="mt-4 prenotazione-dialog-informations">
                  Riceverai una email all'indirizzo <strong>{utente?.email}</strong> con il riepilogo delle prenotazioni aggiornate.
                </p>
              </li>
            )}
          </ol>
          <FixedButtonPrenotazione onClick={() => navigate("/prenotazioni")} version="white" disabled={false} text={"Torna alle prenotazioni"} />
        </div>
      )}
    </>
  );
}
