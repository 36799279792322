/**  */
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { eachDayOfInterval, format, formatISO, parse, parseISO } from "date-fns";
import { Cliente, Prenotazione, PrenotazioneDataString, Targa } from "../../models/model";
import { getJwtToken } from "../auth/auth";
import { apiEndpoint, fetchJson, useMutationWithAuth } from "./config";

export function usePrenotazioniFuture() {
  const path = "/prenotazioni/future";
  return useQuery<Prenotazione[]>({
    queryKey: ["prenotazioni", "future"],
    gcTime: 100,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    queryFn: async (context) => {
      const token = await getJwtToken();

      return fetch(apiEndpoint + path, {
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `bearer ${token}` as string,
        }),
      })
        .then((res) => {
          return res.json() as Promise<PrenotazioneDataString[]>;
        })
        .then((list) => {
          return list.map((a) => {
            const data = parse(a.data, "yyyy-MM-dd", new Date());
            return {
              ...a,
              data,
              ora_ingresso: parse(a.ora_ingresso, "HH:mm:ss", new Date()),
              ora_uscita: parse(a.ora_uscita, "HH:mm:ss", new Date()),

              parcheggio: {
                ...a.parcheggio,
                ora_apertura: parse(a.parcheggio.ora_apertura, "HH:mm:ss", new Date()),
                ora_chiusura: parse(a.parcheggio.ora_chiusura, "HH:mm:ss", new Date()),
              },
              entrata: a?.entrata ? parse(a.entrata, "yyyy-MM-dd HH:mm:ss", new Date()) : undefined,
            };
          });
        });
    },
  });
}
export function usePrenotazioniPassate() {
  const path = "/prenotazioni/passate";
  return useQuery<Prenotazione[]>({
    queryKey: ["prenotazioni", "passate"],
    queryFn: async (context) => {
      const token = await getJwtToken();

      return fetch(apiEndpoint + path, {
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `bearer ${token}` as string,
        }),
      })
        .then((res) => {
          return res.json() as Promise<PrenotazioneDataString[]>;
        })
        .then((list) => {
          return list.map((a) => {
            const data = parse(a.data, "yyyy-MM-dd", new Date());
            return {
              ...a,
              data,
              ora_ingresso: parse(a.ora_ingresso, "HH:mm:ss", new Date()),
              ora_uscita: parse(a.ora_uscita, "HH:mm:ss", new Date()),
              parcheggio: {
                ...a.parcheggio,
                ora_apertura: parse(a.parcheggio.ora_apertura, "HH:mm:ss", new Date()),
                ora_chiusura: parse(a.parcheggio.ora_chiusura, "HH:mm:ss", new Date()),
              },
              entrata: a.entrata ? parse(a.entrata, "yyyy-MM-dd HH:mm:ss", new Date()) : undefined,
            };
          });
        });
    },
  });
}

const createPrenotazione = (
  accessToken: string,
  lotti_parcheggio_id: number,
  data: string,
  fascia_oraria: Cliente["durata_prenotazione"],
  ora_ingresso: string,
  ora_uscita: string,
  posti_codice: string
) =>
  fetchJson<{ id: string }>(apiEndpoint + `/prenotazioni`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      lotti_parcheggio_id,
      data,
      fascia_oraria,
      ora_ingresso,
      ora_uscita,
      posti_codice,
    }),
  });
type CreatePrenotazioneReqFields = {
  lotti_parcheggio_id: number;
  data: string;
  fascia_oraria: Cliente["durata_prenotazione"];
  ora_ingresso: string;
  ora_uscita: string;
  posti_codice: string;
};

export function useCreatePrenotazione() {
  const client = useQueryClient();
  return useMutationWithAuth<{ id: string }, unknown, CreatePrenotazioneReqFields>({
    mutationKey: ["prenotazione", "create"],
    mutationFn: async ({ lotti_parcheggio_id, data, fascia_oraria, ora_ingresso, ora_uscita, posti_codice }) => {
      const token = await getJwtToken();
      if (!token) {
        throw new Error("Errore durante la creazione della prenotazione.. no token");
      }
      const one = await createPrenotazione(token, lotti_parcheggio_id, data, fascia_oraria, ora_ingresso, ora_uscita, posti_codice);
      if (!one) {
        throw new Error("Errore durante la creazione della targa");
      }
      return one;
    },
    onSettled: () => {
      client.invalidateQueries({
        queryKey: ["prenotazioni"],
      });
    },
  });
}

export function useDeletePrenotazione(id: Prenotazione["id"]) {
  const client = useQueryClient();
  return useMutation<void, unknown, any>({
    mutationKey: ["prenotazione", id, "delete"],
    mutationFn: async () => {
      const token = await getJwtToken();
      const res = await fetch(`${apiEndpoint}/prenotazioni/${id}/delete`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!res.ok) {
        const json = await res.json();

        throw new Error(json?.message || "Errore durante la cancellazione della prenotazione");
      }
    },
    onSettled: () => {
      client.invalidateQueries({
        queryKey: ["prenotazioni"],
      });
    },
    onError: (error) => {
      console.error("Errore durante la cancellazione della prenotazione:", error);
    },
  });
}
export function useDeleteablePrenotazione(id: Prenotazione["id"]) {
  return useQuery<null, string | null>({
    queryKey: ["prenotazione", id, "deleteable"],

    queryFn: async () => {
      const token = await getJwtToken();
      const res = await fetch(`${apiEndpoint}/prenotazioni/${id}/deleteable`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!res.ok) {
        const json = await res.json();

        throw json?.message || "Errore durante la cancellazione della prenotazione";
      }
      return null;
    },
  });
}

export function useHaDisponibilita() {
  const { isLoading, data } = useDisponibilitaPrenotazioni();
  if (isLoading || !data) {
    return undefined;
  }
  const eachDay = eachDayOfInterval({
    start: data.minDate,
    end: data.maxDate,
  });
  return eachDay.length > data.notAvailable.length;
}

export function useDisponibilitaPrenotazioni() {
  const path = "/prenotazioni/disponibilita";
  return useQuery<{
    notAvailable: Date[];
    minDate: Date;
    maxDate: Date;
  }>({
    queryKey: ["prenotazioni", "disponibilita"],
    queryFn: async (context) => {
      const token = await getJwtToken();
      const res = await fetch(apiEndpoint + path, {
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `bearer ${token}` as string,
        }),
      });
      const resJSON: {
        notAvailable: string[];
        minDate: string;
        maxDate: string;
      } = await res.json();
      return {
        minDate: parse(resJSON.minDate, "yyyy-MM-dd", new Date()),
        maxDate: parse(resJSON.maxDate, "yyyy-MM-dd", new Date()),
        notAvailable: resJSON.notAvailable.map((el) => parse(el, "yyyy-MM-dd", new Date())),
      };
    },
  });
}

type DuplicaPrenotazioneResData = {
  message: string;
  prenotazione_duplicata: PrenotazioneDuplicata;
  request: DateRange[];
  success: SuccessPrenotazione[];
  error: ErrorInfo[];
};

interface PrenotazioneDuplicata {
  id: number;
  posti_parcheggi_id: number;
  posti_codice: string;
  lotti_parcheggio_id: number;
  clienti_id: number;
  utenti_sub: string;
  data: string;
  targa: string;
  ora_ingresso: string;
  ora_uscita: string;
  nota: any;
  author_sub: any;
  prenotata_da_sub: any;
  data_sent_ext: any;
  data_delete_ext: any;
  eliminato: any;
  entrata: any;
  creata: string;
}

interface DateRange {
  dataInizio: Date; 
  dataFine: Date;
}

interface SuccessPrenotazione {
  posti_parcheggi_id: number;
  posti_codice: string;
  lotti_parcheggio_id: number;
  clienti_id: number;
  utenti_sub: string;
  targa: string;
  data: string;
  nota: string;
  author_sub: any;
  prenotata_da_sub: any;
  ora_ingresso: string;
  ora_uscita: string;
  id: number;
}

interface ErrorInfo {
  date: string;
  message: string;
}

export function useDuplicaPrenotazione(id: Prenotazione["id"]) {
  return useMutation<DuplicaPrenotazioneResData, Error, DateRange>({
    mutationKey: ["prenotazione", id, "duplicate"],
    mutationFn: async ({ dataInizio, dataFine }) => {
      const path = `/prenotazioni/${id}/duplicate`;
      const fromDateFormatted = format(dataInizio, "yyyy-MM-dd");
      const toDateFormatted = format(dataFine, "yyyy-MM-dd");
      const token = await getJwtToken();
      if (!token) {
        throw new Error("Errore durante la duplicazione della prenotazione.. no token");
      }
      const res = await fetchJson(apiEndpoint + path, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          startDate: fromDateFormatted,
          endDate: toDateFormatted,
        }),
      }) as DuplicaPrenotazioneResData;
      if (!res) {
        throw new Error("Errore durante la duplicazione della prenotazione");
      }
      return res;
    }
  });
}

interface SostituisciTargaPrenotazioniResData {
  request: []
  message: string
  targa: []
  prenotazioni: []
}

export function useAggiornaTargaPrenotazioniFuture(idTarga: Targa["id"]) {
  return useMutation<SostituisciTargaPrenotazioniResData, Error, any>({
    mutationKey: ["prenotazioni", "future"],
    mutationFn: async ({ dataInizio, dataFine, targaAggiornata, notaAggiornata }) => {
      const path = `/prenotazioni/future/${idTarga}/edit`;
      const fromDateFormatted = format(dataInizio, "yyyy-MM-dd");
      const toDateFormatted = format(dataFine, "yyyy-MM-dd");
      const token = await getJwtToken();
      if (!token) {
        throw new Error("Errore durante l'aggiornamento delle prenotazioni.. no token");
      }
      const res = await fetchJson(apiEndpoint + path, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          startDate: fromDateFormatted,
          endDate: toDateFormatted,
          updatedPlate: targaAggiornata,
          updatedNote: notaAggiornata
        }),
      }) as SostituisciTargaPrenotazioniResData;
      if (!res) {
        throw new Error("Errore durante l'aggiornamento delle prenotazioni");
      }
      return res;
    }
  });
}

export function useAttivaTargaPrenotazioniFuture(idTarga: Targa["id"]) {
  return useMutation<SostituisciTargaPrenotazioniResData, Error, any>({
    mutationKey: ["prenotazioni", "future"],
    mutationFn: async ({ dataInizio, dataFine, targaAggiornata, notaAggiornata }) => {
      const path = `/prenotazioni/future/${idTarga}/switch`;
      const fromDateFormatted = format(dataInizio, "yyyy-MM-dd");
      const toDateFormatted = format(dataFine, "yyyy-MM-dd");
      const token = await getJwtToken();
      if (!token) {
        throw new Error("Errore durante l'aggiornamento delle prenotazioni.. no token");
      }
      const res = await fetchJson(apiEndpoint + path, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          startDate: fromDateFormatted,
          endDate: toDateFormatted
        }),
      }) as SostituisciTargaPrenotazioniResData;
      if (!res) {
        throw new Error("Errore durante l'aggiornamento delle prenotazioni");
      }
      return res;
    }
  });
}