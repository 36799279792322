import { TextField } from "@mui/material";
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import React, { FormEvent, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CognitoAuthContext, IDP, getJwtToken, getSession, idps, isValid, setCognitoUser, userPool } from "../../services/auth/auth";
import { getUserManager } from "../../services/auth/oidc_auth";

function LoginWithSystemCredentials({ username, resetLoginType }: { username: string; resetLoginType: () => void }) {
  const auth_type = useContext(CognitoAuthContext);
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(
    new CognitoUser({
      Pool: userPool,
      Username: username,
    })
  );
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>("");
  const [requiredAttributes, setRequiredAttributes] = useState("");
  const [authError, setAuthError] = useState(undefined);
  const [status, setStatus] = useState<"login" | "mfa" | "changePassword">("login");

  const navigate = useNavigate();
  useEffect(() => {
    getSession()
      .then((cognitoUserSession: CognitoUserSession | undefined) => {
        // add refresh?
        isValid().then((is_session_valid) => {
          if (is_session_valid) {
            navigate("/");
          }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, [navigate, auth_type]);

  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });
  useEffect(() => {
    setUser(
      new CognitoUser({
        Pool: userPool,
        Username: username,
      })
    );
  }, [username]);

  const doLogin = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    user.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        setCognitoUser(userPool.getCurrentUser(), "with_credentials");
        navigate("/");
      },
      onFailure: (err) => {
        setAuthError(err?.message || `Auth Error (${err?.name || "Unknown"})`);
        console.log(err);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        // the api doesn't accept this field back
        delete userAttributes.email_verified;
        setRequiredAttributes(requiredAttributes);
        // store userAttributes on global variable
        console.log(userAttributes, requiredAttributes);
        setStatus("changePassword");
        setAuthError(undefined);
      },
    });
  };
  const doChangePassword = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    user.completeNewPasswordChallenge(newPassword, requiredAttributes, {
      onSuccess: (result) => {
        navigate("/");
      },
      onFailure: (err) => {
        setAuthError(err?.message || `Auth Error (${err?.name || "Unknown"})`);
        console.log(err);
      },
    });
  };
  let helperText = undefined;
  if (newPassword !== newPasswordConfirmation) {
    helperText = "Le password non combaciano";
  }
  return (
    <div className="h-screen w-screen grid grid-cols-1 md:grid-cols-2">
      {status === "login" && (
        <div className="w-100 md:w-50 h-full">
          <div className="flex flex-col m-auto justify-center h-full login-container max-w-[80vw] md:max-w-[50%]">
            <div className="parcheggio-milano-fiori-nord-tc-030303 title-extra-big !font-bold text-center">BENTORNATO</div>
            <div className="parcheggio-milano-fiori-nord-tc-636364 text-normal !font-regular text-center">
              Bentornato su Parcheggi Milanofiori Nord! Per favore inserisci le tue credenziali.
            </div>
            <form className="flex flex-col" onSubmit={(event) => doLogin(event)} action="">
              <TextField value={username} InputProps={{ readOnly: true }} label="Email" variant="outlined" disabled />
              <TextField
                value={password}
                type="password"
                onChange={(event) => {
                  const trimmedPassword = event.currentTarget.value.replace(/\s/g, "");
                  setPassword(trimmedPassword);
                }}
                label="Password"
                variant="outlined"
              />
              <button type="submit" disabled={!username || !password} className="big-button button-third w-100 text-big !font-bold text-white">
                Entra
              </button>
            </form>
            <div className="flex justify-between">
              <div className="text-normal text-end font-bold max-w-[80vw] md:max-w-[50%]">
                <button onClick={resetLoginType} type="button">
                  <strong className="underline">Torna Indietro</strong>
                </button>
              </div>
              <div className="text-normal text-end font-bold max-w-[80vw] md:max-w-[50%]">
                <Link to="/forgot-password">
                  <strong className="underline">Password dimenticata?</strong>
                </Link>
              </div>
            </div>
            <div>{authError && <div className="text-normal text-red-500 font-bold">{authError}</div>}</div>
          </div>
          <div className="login-mobile-image md:hidden">
            <img className="header-image" src={`${process.env.PUBLIC_URL}/Milanofiori-tagline-Main-logo-H-N.png`} alt="Milanofiori-tagline-Main-logo-H-N" />
          </div>
        </div>
      )}
      {status === "changePassword" && (
        <div className="w-100 md:w-50 h-full">
          <div className="flex flex-col m-auto justify-center h-full login-container max-w-[80vw] md:max-w-[50%]">
            <div className="text-big !font-bold">Imposta nuova password</div>
            <form className="flex flex-col" onSubmit={(event) => doChangePassword(event)} action="">
              <TextField
                type="password"
                value={newPassword}
                onChange={(event) => {
                  const trimmedPassword = event.currentTarget.value.replace(/\s/g, "");
                  setNewPassword(trimmedPassword);
                }}
                label="Nuova Password"
                variant="outlined"
              />
              <TextField
                type="password"
                value={newPasswordConfirmation}
                onChange={(event) => {
                  const trimmedPassword = event.currentTarget.value.replace(/\s/g, "");
                  setNewPasswordConfirmation(trimmedPassword);
                }}
                helperText={helperText}
                label="Nuova Password"
                variant="outlined"
              />
              <button
                type="submit"
                disabled={!newPassword || !newPasswordConfirmation || newPassword !== newPasswordConfirmation}
                className="button-third !font-bold w-100"
              >
                Entra
              </button>
            </form>
            <div>{authError && <div className="text-normal text-red-500 font-bold">{authError}</div>}</div>
            <div className="text-normal font-bold">
              La password deve:
              <ul>
                <li>Essere lunga almeno 8 caratteri.</li>
                <li>
                  Avere almeno uno dei seguenti criteri:
                  <div>Contenere almeno un caratere numerico</div>
                  <div>Contenere almeno un carattere speciale</div>
                  <div>Contenere almeno un carattere maiuscolo</div>
                  <div>Contenere almeno un carattere minuscolo</div>
                </li>
              </ul>
            </div>
          </div>
          <div className="login-mobile-image md:hidden">
            <img className="header-image" src={`${process.env.PUBLIC_URL}/Milanofiori-tagline-Main-logo-H-N.png`} alt="Milanofiori-tagline-Main-logo-H-N" />
          </div>
        </div>
      )}
      <div className="hidden md:block md:w-50 h-full parcheggio-milano-fiori-nord-bg-primary">
        <div className="flex flex-col m-auto justify-center h-full max-w-[80vw] md:max-w-[50%]">
          <img src={`${process.env.PUBLIC_URL}/Milanofiori-tagline-Main-logo-H-N-white.png`} alt="" />
        </div>
      </div>
    </div>
  );
}

export function LoginHandler({ changeLoginType }: { changeLoginType: React.Dispatch<React.SetStateAction<"with_credentials" | "oidc" | null>> }) {
  
  const [username, setUsername] = useState("");

  const auth_type = useContext(CognitoAuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    let ignore = false;
    const checkSession = async () => {
      if (ignore) {
        return;
      }
      try {
        const is_session_valid = await isValid();
        const jwtToken = await getJwtToken();
        if (is_session_valid && jwtToken) {
          navigate("/");
        }
      } catch (err) {
        console.error(err);
      }
    };
    checkSession();
    return () => {
      ignore = true;
    };
  }, [auth_type, navigate]);
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Regular expression for email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    /* Controllo se lo username sia una stringa e se contiene uno dei domini che hanno il login aziendale */
    if (emailRegex.test(username) && idps.find((idp) => username.toLowerCase().endsWith(idp.domain))) {
      const idp = idps.find((idp) => username.toLowerCase().endsWith(idp.domain.toLowerCase()))!;
      /* Significa che l'utente deve fare login con le credenziali aziendali */
      localStorage.setItem(
        "last_signinRedirect",
        JSON.stringify({
          idp,
          username,
          timestamp: new Date().getTime(),
        })
      );
      const userManager = getUserManager();
      userManager
        .signinRedirect({
          extraQueryParams: {
            identity_provider: idp.cognito_idp_idefier,
          },
        })
        .then(() => {
          console.log("redirected");
        })
        .catch((err: any) => {
          navigate("/login");
        });
    } else {
      /* Significa che l'utente deve fare login con le credenziali normali */
      changeLoginType("with_credentials");
    }
  };
  return (
    <>
      {/* Nel caso in cui l'utente non ha ancora cliccato sul tasto avanti*/}
      {!auth_type && (
        <div className="h-screen w-screen grid grid-cols-1 md:grid-cols-2">
          <div className="w-100 md:w-50 h-full">
            <div className="flex flex-col m-auto justify-center h-full login-container max-w-[80vw] md:max-w-[50%]">
              <div className="parcheggio-milano-fiori-nord-tc-030303 title-extra-big !font-bold text-center">BENTORNATO</div>
              <div className="parcheggio-milano-fiori-nord-tc-636364 text-normal !font-regular text-center">
                Bentornato su Parcheggi Milanofiori Nord! Per favore inserisci le tue credenziali.
              </div>
              <form className="flex flex-col" onSubmit={(event) => handleSubmit(event)} action="">
                <TextField value={username} onChange={(event) => setUsername(event.currentTarget.value.replace(/\s/g, ""))} label="Email" variant="outlined" />
                <button type="submit" disabled={!username} className="big-button button-third w-100 text-big !font-bold text-white">
                  Avanti
                </button>
              </form>
            </div>
            <div className="login-mobile-image md:hidden">
              <img className="header-image" src={`${process.env.PUBLIC_URL}/Milanofiori-tagline-Main-logo-H-N.png`} alt="Milanofiori-tagline-Main-logo-H-N" />
            </div>
          </div>
          <div className="hidden md:block md:w-50 h-full parcheggio-milano-fiori-nord-bg-primary">
            <div className="flex flex-col m-auto justify-center h-full max-w-[80vw] md:max-w-[50%]">
              <img src={`${process.env.PUBLIC_URL}/Milanofiori-tagline-Main-logo-H-N-white.png`} alt="" />
            </div>
          </div>
        </div>
      )}
      {/* Nel caso in cui l'utente ha cliccato sul tasto avanti e deve fare login con le credenziali del sistema */}
      {auth_type === "with_credentials" && username && <LoginWithSystemCredentials username={username} resetLoginType={() => changeLoginType(null)} />}
    </>
  );
}
