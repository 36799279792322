import { useNavigate } from "react-router-dom"
import FixedButtonPrenotazione from "../FixedButtonPrenotazione"

export default function ErroreDialog({
  errorMessage
}: {
  errorMessage?: string
}) {
  const navigate = useNavigate()
  const pathname = window.location.pathname
  return <div className="errore-dialog-container">

    <ol>
      <li>
        <img src={`${process.env.PUBLIC_URL}/Milanofiori-error.png`} alt="Icona Errore" />
      </li>
      <li className="mt-10">
        <h1>Ops... Si è verificato un errore!</h1>
      </li>
      <li className="mt-4">
        {errorMessage &&
          <p className="errore-dialog-informations">{errorMessage}</p>
        }
      </li>
      <li>
        {pathname !== '/' &&
          <FixedButtonPrenotazione
            onClick={() => { navigate(-1)}}
            version="white"
            disabled={false}
            text={"Torna indietro"} />
        }
      </li>
    </ol>
  </div>
}