
import { formatISO, isBefore, parseISO } from "date-fns";
import { is } from "date-fns/locale";
import { jwtDecode, JwtPayload } from "jwt-decode";
const allowedIssuers = [
  "parcheggio-milanofiori.wp.jef.it",
  "parcheggimilanofiorinord.it",
]
export async function getSession(): Promise<any> {}
function getUsername(){
  return "username"
}
export function setUser(user: any) {
  const accessTokenKey = `TemporaryUser.accessToken`;
  window.sessionStorage.setItem(accessTokenKey, user.accessToken);
}
export function setToken(token: string) {
  const accessTokenKey = `TemporaryUser.accessToken`;
  window.sessionStorage.setItem(accessTokenKey, token);
}
export function logOutUser(){
  const accessTokenKey = `TemporaryUser.accessToken`;
  window.sessionStorage.removeItem(accessTokenKey);
  return;
}
export function getTemporatyToken(): string | null{
  const accessTokenKey = `TemporaryUser.accessToken`;
  return window.sessionStorage.getItem(accessTokenKey);
}
function decodePayload(jwtToken: string) {
  try{
    const decoded = jwtDecode(jwtToken);
    return decoded
  }catch(err){
    throw new Error("Invalid token")
  }
 
}
export function isTemporaryTokenValid(token: string){
  const jwtPayload: JwtPayload =  decodePayload(token);
  if(!jwtPayload?.exp || (jwtPayload.exp && isBefore(parseISO(formatISO(jwtPayload?.exp*1000)), new Date()))){
    console.error("Temporary Authentication: Token expired")
    return false;
  }
  if(!jwtPayload?.iss || (jwtPayload?.iss && !allowedIssuers.some((el) => jwtPayload.iss?.includes(el)))  ){
    console.error("Temporary Authentication: Invalid issuer")
    return false;
  }
  
  return true;
}
export function initTemporarySession(token: string){
  if(!isTemporaryTokenValid(token)){
    throw new Error("Invalid token")
  }
  setToken(token);
}

