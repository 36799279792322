import {
  CognitoUser as WithCredentialsCognitoUser,
  CognitoUserAttribute as WithCredentialsCognitoUserAttribute,
  CognitoUserSession as WithCrediantsUserSession,
} from "amazon-cognito-identity-js";

import {
  OIDCCognitoUser,
  OIDCCognitoUserAttribute,
  getSession as OIDCGetSession,
  getUsername as OIDCGetUsername,
  getCognitoUserWithSession as OIDCGetCognitoUserWithSession,
  getUserAttributes as OIDCGetUserAttributes,
  logOutUser as OIDCLogOutUser,
  OIDCUSerSession,
} from "./oidc_auth";
import {
  userPool,
  getSession as withCredetialsGetSession,
  getUsername as withCredetialsGetUsername,
  setCognitoUser as withCredetialsSetCognitoUser,
  getCognitoUserWithSession as withCredetialsGetCognitoUserWithSession,
  getUserAttributes as withCredetialsGetUserAttributes,
  logOutUser as withCredetialsLogOutUser,
} from "./with_credentials_auth";
import {
  logOutUser as temporaryLogOutUser,
} from "./temporary_auth";
import React from "react";
import { IDP, idps } from "./oidc_auth";
import { IdTokenClaims } from "oidc-client-ts";
import { getTemporatyToken } from "./temporary_auth";

export { idps, userPool };
export type { IDP };

export type UserSession = WithCrediantsUserSession | OIDCUSerSession;
type CognitoUser = WithCredentialsCognitoUser | OIDCCognitoUser;
type CognitoUserAttribute = WithCredentialsCognitoUserAttribute | OIDCCognitoUserAttribute;
export type AuthType = "with_credentials" | "oidc" | null;
export const CognitoAuthContext = React.createContext<AuthType>(null);

export async function getSession(): Promise<UserSession | undefined> {
  let session: UserSession | undefined;
  const with_credentials_session = await withCredetialsGetSession();
  if (with_credentials_session) {
    session = with_credentials_session;
  } else {
    const oidc_session = await OIDCGetSession();
    if (oidc_session) {
      session = oidc_session;
    }
  }
  return session; // Nel caso peggiore è andefined
}
export async function getJwtToken(): Promise<string | null> {
  let jwt_token: string | null = null;
  const temporary_token = getTemporatyToken();
  const with_credentials_session = await withCredetialsGetSession();
  if (temporary_token) {
    jwt_token = temporary_token;
  } else if (with_credentials_session) {
    jwt_token = with_credentials_session.getIdToken().getJwtToken();
  } else {
    const oidc_session = await OIDCGetSession();
    if (oidc_session) {
      jwt_token = oidc_session.id_token || null;
    }
  }
  return jwt_token;
}
export function setCognitoUser(user: CognitoUser | null, auth_type: "with_credentials" | "oidc"): void {
  /* In questo caso non posso farne a meno di gestire l'auth_type, solo che l'entità che richiama il metodo sà quel'è
    l'auth_type dal momento che il metodo setCognitoUser() viene richiamato dai componenti di login (LoginWithSystemCredentials)  */
  if (auth_type === "with_credentials") {
    withCredetialsSetCognitoUser(user);
  } else {
    /* Todo: Non so se devo gestire il set dell'utente cognito per quanto riguarda l'oidc dal momento che 
        forse lo fa la libreria nativamente */
  }
  return;
}
export async function getUsername(): Promise<string> {
  let username = "";
  const with_credentials_username = withCredetialsGetUsername();
  if (!!with_credentials_username) {
    username = with_credentials_username;
  } else {
    const oidc_username = await OIDCGetUsername();
    username = oidc_username ? oidc_username : "";
  }
  return username;
}

export async function getCognitoUserWithSession(): Promise<CognitoUser | null> {
  let cognitoUserWithSession: CognitoUser | null = null;
  let with_credentials_cognito_user_with_session = await withCredetialsGetCognitoUserWithSession();

  if (with_credentials_cognito_user_with_session) {
    cognitoUserWithSession = with_credentials_cognito_user_with_session;
  } else {
    let oidc_cognito_user_with_session = await OIDCGetCognitoUserWithSession();
    cognitoUserWithSession = oidc_cognito_user_with_session ? oidc_cognito_user_with_session : null;
  }
  return cognitoUserWithSession;
}

export async function getUserAttributes(): Promise<CognitoUserAttribute[] | IdTokenClaims | undefined> {
  let attributes: CognitoUserAttribute[] | IdTokenClaims | undefined = undefined;
  const with_credentials_user_attributes = await withCredetialsGetUserAttributes();
  if (!!with_credentials_user_attributes) attributes = with_credentials_user_attributes;
  else {
    const oidc_user_attributes = await OIDCGetUserAttributes();
    attributes = oidc_user_attributes ? oidc_user_attributes : [];
  }
  return attributes;
}
export async function isValid() {
  const with_credentials_session = await withCredetialsGetSession();
  if (with_credentials_session) {
    return with_credentials_session.isValid();
  } else {
    const oidc_session = await OIDCGetSession();

    return oidc_session?.expired === false;
  }
}
export async function logOutUser(): Promise<void> {
  /* Le faccio entrambe */
  try {
    const with_credentials_session = await withCredetialsGetSession();
    const temporary_token = getTemporatyToken();
    if (temporary_token) {
      temporaryLogOutUser();
      /* Faccio return altrimenti termina tutte le sessioni e slogga anche l'utente che ha aperto la web-app tramite il token */
      return;
    } else if (with_credentials_session) {
      await withCredetialsLogOutUser();
    } else {
      await OIDCLogOutUser();
    }
  } catch (err) {
    console.error(err);
  }
}
