import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { format, isSameDay, addDays, isAfter } from "date-fns";
import it from "date-fns/locale/it";
import Container from "@mui/material/Container";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { itIT } from "@mui/x-date-pickers/locales";
import { usePrenotazioniFuture, useDuplicaPrenotazione } from "../../services/api/prenotazioni";
import { useUtente } from "../../services/api/utente";
import { useCliente } from "../../services/api/cliente";
import FixedButtonPrenotazione from "../FixedButtonPrenotazione";
import FixedButtonDuplicaPrenotazione from "../FixedButtonDuplicaPrenotazione";
import ErroreDialog from "../shared/ErroreDialog";

export default function DuplicaPrenotazione() {
  const navigate = useNavigate();
  const { id } = useParams();
  const intId = parseInt(id as string);

  const { mutate: duplicaPrenotazione, isPending, isSuccess, isError: isErrorDuplicationPrenotazione, error, data } = useDuplicaPrenotazione(intId);

  const { data: prenotazioniFuture, isLoading: isPrenotazioniFutureLoading } = usePrenotazioniFuture();
  const prenotazione = useMemo(() => prenotazioniFuture?.find((el) => el.id === intId), [intId, prenotazioniFuture]);

  const { data: utente, error: isUtenteError, isLoading: isUtenteLoading } = useUtente();
  const { data: cliente, error: isClienteError, isLoading: isClienteLoading } = useCliente();

  const minDate = useMemo(() => addDays(prenotazione?.data || new Date(), 1), [prenotazione?.data]);
  const [startDate, setStartDate] = useState<Date>(minDate);
  const maxDate = useMemo(() => {
    const dataScadenzaContrattoCliente = cliente?.scadenza_contratto;
    return dataScadenzaContrattoCliente ? new Date(dataScadenzaContrattoCliente) : addDays(startDate, 30);
  }, [cliente, startDate]);
  const [endDate, setEndDate] = useState<Date>(maxDate);

  useEffect(() => {
    if (!isPrenotazioniFutureLoading && !prenotazione) {
      navigate("/");
    }
  }, [prenotazione, isPrenotazioniFutureLoading, navigate]);

  useEffect(() => {
    setStartDate(minDate);
  }, [minDate]);
  useEffect(() => {
    setEndDate((prev) => (isAfter(prev, maxDate) ? maxDate : prev));
  }, [maxDate]);

  const isDuplicaDisabled = useMemo(() => {
    return !startDate || !endDate || isAfter(startDate, endDate) || isPending;
  }, [startDate, endDate, isPending]);

  const handleConfirmClick = () => {
    if (!startDate || !endDate) return;

    duplicaPrenotazione(
      { dataInizio: startDate, dataFine: endDate },
      {
        onSuccess: () => {
          navigate(`/prenotazioni/${intId}/duplica`);
        },
        onError: (error) => {
          console.error("Errore durante la duplicazione:", error.message);
        },
      }
    );
  };

  if (isPrenotazioniFutureLoading && !prenotazione) {
    return <div className="absolute-centered"><CircularProgress /></div>;
  }

  return (
    <section>
      <div className="flex flex-col md:flex-row justify-start md:justify-between items-start md:items-center text-start">
        <div className="page-title-container">
          <h1>Duplica prenotazione</h1>
        </div>
      </div>
      {!isPrenotazioniFutureLoading && prenotazione && (
        <>
          <div className="flex flex-col justify-start items-start text-start">
            <div className="mt-2">
              <h3>
                {format(prenotazione?.data, "dd")}&nbsp;
                {format(prenotazione?.data, "MMMM", { locale: it })}&nbsp;
                {format(prenotazione?.data, "Y")}
              </h3>
            </div>
            <ul className="mt-4 ml-1 prenotazione-futura-detail">
              <li>
                <p>
                  <strong>Parcheggio:</strong> {prenotazione.parcheggio.nome_parcheggio}
                </p>
              </li>
              <li>
                <p>
                  <strong>Lotto:</strong> {prenotazione.lotto.nome_parcheggio}
                </p>
              </li>
              {prenotazione.lotto?.scelta_posto === true && (
                <li>
                  <p>
                    <strong>Posto:</strong> {prenotazione.posti_codice}
                  </p>
                </li>
              )}
              <li>
                <p>
                  <strong>Fascia oraria:</strong> {format(prenotazione.ora_ingresso, "HH:mm")} - {format(prenotazione.ora_uscita, "HH:mm")}
                </p>
              </li>
              <li>
                <p>
                  <strong>Orari Parcheggio:</strong> {format(prenotazione.parcheggio.ora_apertura, "HH:mm")} -{" "}
                  {format(prenotazione.parcheggio.ora_chiusura, "HH:mm")}
                </p>
              </li>
              <li>
                <p>
                  <strong>Targa:</strong> {prenotazione.targa}
                </p>
              </li>
            </ul>
          </div>

          <div className="flex md:flex-row justify-start md:justify-between items-start md:items-center text-start mt-2">
            <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale={it} dateAdapter={AdapterDateFns}>
              <Container className="!pl-0">
                <h3 className="!text-start">da</h3>
                <DatePicker
                  label="Data inizio"
                  className="w-full !mt-4"
                  value={startDate}
                  format="dd/MM/yyyy"
                  shouldDisableDate={(date) => isSameDay(date, new Date(prenotazione.data))}
                  onChange={(e) => setStartDate(e ? new Date(e.getTime()) : startDate)}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </Container>
              <Container className="!pr-0">
                <h3 className="!text-start">a</h3>
                <DatePicker
                  label="Data fine"
                  className="w-full !mt-4"
                  value={endDate}
                  format="dd/MM/yyyy"
                  shouldDisableDate={(date) => isSameDay(date, new Date(prenotazione.data))}
                  onChange={(e) => setEndDate(e ? new Date(e.getTime()) : endDate)}
                  minDate={startDate}
                  maxDate={maxDate}
                />
              </Container>
            </LocalizationProvider>
          </div>

          <div className="flex flex-col md:flex-row justify-start md:justify-between items-start md:items-center text-start">
            <div className="mt-4 information-container">
              <InfoIcon />
              <span className="mt-2 informativa-duplicazione">
                L'attuale prenotazione verrà duplicata automaticamente nei giorni compresi tra la data di inizio e la data di fine selezionate (a partire dal
                giorno successivo alla prenotazione attuale e non oltre la data di termine utilizzo definita per la tua azienda).
                <br />
                <u>
                  La duplicazione non verrà effettuata nei i giorni in cui hai già attiva una prenotazione, o quando non risultano posti disponibili nel lotto
                </u>
                .
                <br />
                Se hai bisogno di assistenza, puoi contattarci al seguente indirizzo:
                <br />
                <a className="font-bold underline" href="mailto:gestione-park@comprensoriomfnord.it">
                  gestione-park@comprensoriomfnord.it
                </a>
                .
              </span>
            </div>
          </div>

          <FixedButtonDuplicaPrenotazione
            onClick={handleConfirmClick}
            version="blue"
            disabled={isDuplicaDisabled}
            text={isPending ? "Invio in corso ..." : "Conferma"}
          />

          <FixedButtonPrenotazione onClick={() => navigate("/prenotazioni")} version="white" disabled={false} text={"Torna alle prenotazioni"} />
        </>
      )}

      {isPending && (
        <Backdrop sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })} open={true}>
          <div className="flex flex-col items-center">
            <CircularProgress color="inherit" />
            <p className="mt-4">L'operazione potrebbe richiedere qualche secondo... Attendere prego.</p>
          </div>
        </Backdrop>
      )}

      {isErrorDuplicationPrenotazione && <ErroreDialog errorMessage={(error?.message as string) || "Errore durante la duplicazione della prenotazione"} />}

      {isSuccess && utente && (
        <div className="prenotazione-dialog-container">
          <ol>
            <li>
              <img src={`${process.env.PUBLIC_URL}/${"confirmed"}-icon.svg`} alt={"confirmed"} />
            </li>
            <li className="mt-10">
              <h1>Perfetto!</h1>
            </li>
            <li className="mt-4">
              <h3 className="prenotazione-dialog-informations">La tua prenotazione è stata duplicata con successo.</h3>
            </li>
            <li className="mt-4">
              <p className="mt-4 prenotazione-dialog-informations">
                Riceverai una email all'indirizzo <strong>{utente.email}</strong> contenente il riepilogo delle prenotazioni effettuate.
              </p>
            </li>
            {data.error && data.error?.length > 0 && (
              <div className="mt-4 error-container">
                <h3>Attenzione!</h3>
                <p>Alcune prenotazioni non sono state inserite per i seguenti motivi:</p>
                <ol className="mt-2 list-disc list-inside">
                  {/* mostro le prime 5 date non prenotate */}
                  {data.error.slice(0, 5).map((err: { date: string; message: string }, index: number) => (
                    <li key={index}>
                      <strong>{err.date}</strong>, {err.message}
                    </li>
                  ))}
                  {data.error?.length > 5 && <span>... (vedi l'elenco completo nell'email di riepilogo)</span>}
                </ol>
              </div>
            )}
            <li>
              <FixedButtonPrenotazione onClick={() => navigate("/prenotazioni")} version="white" disabled={false} text={"Torna alle prenotazioni"} />
            </li>
          </ol>
        </div>
      )}
    </section>
  );
}
