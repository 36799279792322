import { DefaultError, QueryClient, QueryFunction, QueryFunctionContext, QueryKey, UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useAccessTokenProvider } from "../../hooks/useAccessTokenProvider";

export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT as string
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnMount: false,
      refetchOnWindowFocus: false,

    },
  },
});

export const fetchJson = <T>(input: RequestInfo | URL, init?: RequestInit | undefined): Promise<T> => fetch(input, init)
  .then(
    async response => {
      try {
        const responseBody = await response.json();
        if (!response.ok) {
          throw responseBody;
        }
        return responseBody as T
      } catch (e) {
        throw e;
      }
    }
  );

export function useMutationWithAuth<TData = unknown, TError = DefaultError, TVariables = void, TContext = unknown>(
  options: UseMutationOptions<TData, TError, TVariables & { accessToken: string }, TContext>,
): UseMutationResult<TData, TError, TVariables & { accessToken: string }, TContext> {
  const accessTokenProvider = useAccessTokenProvider();
  const newOptions = useMemo(() => {
    const originalMutationFn = options.mutationFn;
    const newMutationFn = async (props: TVariables) => {
      const accessToken = await accessTokenProvider();
      return originalMutationFn!({ ...props, accessToken })
    };
    return {
      ...options,
      mutationFn: newMutationFn
    }
  }, [options, accessTokenProvider])
  return useMutation<TData, TError, TVariables & { accessToken: string }, TContext>(newOptions);
}

export function useQueryWithAuth<TQueryFnData = unknown, TError = DefaultError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResult<TData, TError> {
  const accessTokenProvider = useAccessTokenProvider();
  const newOptions = useMemo(() => {
    const originalQueryFn = options.queryFn as QueryFunction<TQueryFnData, TQueryKey, never>;
    const newQueryFn = async (context: QueryFunctionContext<TQueryKey>) => {
      const accessToken = await accessTokenProvider();
      const newContext = {
        ...context,
        meta: {
          ...context.meta,
          accessToken,
        }
      }
      return originalQueryFn!(newContext)
    };
    return {
      ...options,
      queryFn: newQueryFn
    }
  }, [options, accessTokenProvider])
  return useQuery<TQueryFnData, TError, TData, TQueryKey>(newOptions);
}