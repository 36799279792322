import {Skeleton, TextField} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {CognitoUser} from "../../models/model";
import {TokenContext} from "../../providers/TokenProvider";
import {useModifyUtente, useUtente} from "../../services/api/utente";
import FixedButtonPrenotazione from "../FixedButtonPrenotazione";
import ErroreDialog from "../shared/ErroreDialog";
import {PageTitle} from "../shared/PageTitle";
import SuccessDialog from "../shared/SuccessDialog";

export default function InformazioniPersonali() {
  const {token} = useContext(TokenContext);

  const [nome, setNome] = useState<CognitoUser["family_name"] | null>(null);
  const [cognome, setCognome] = useState<CognitoUser["family_name"] | null>(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const [errorDialog, setErriorDialog] = useState(false)
  const {
    mutateAsync,
  } = useModifyUtente();
  const handleSubmit = async (event: any | undefined) => {
    event?.preventDefault()
    if (nome === null || cognome === null) return
    mutateAsync({accessToken: token, nome, cognome})
      .then(() => {
        setShowSuccessMessage(true)
      }).catch((err) => {
      setErriorDialog(true)
    })
  }

  const {
    data: utente,
    isLoading: isUtenteLoading,
  } = useUtente()
  useEffect(() => {
    if (utente) {
      setNome(utente.nome)
      setCognome(utente.cognome)
    }
  }, [utente])
  return <section>
    <PageTitle
      show_back_button_on_mobile={true}
      title="Informazioni Personali"
    />
    <div className="mt-7">
      {errorDialog &&
        <ErroreDialog/>
      }
      {showSuccessMessage &&
        <SuccessDialog/>
      }
      <form className="flex flex-col" onSubmit={(event) => handleSubmit(event)} action="">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {isUtenteLoading &&
            <>
              <Skeleton className="mt-4 !scale-1 w-full" height={100}></Skeleton>
              <Skeleton className="mt-4 !scale-1 w-full" height={100}></Skeleton>
            </>
          }
          {!isUtenteLoading &&
            <>
              <TextField
                error={!nome || nome === ''}
                autoComplete="off"
                disabled={isUtenteLoading}
                name="nome"
                onChange={(event) => setNome(event.currentTarget.value)}
                label={"Inserisci Nome"}
                value={nome}
                placeholder={"Inserisci Nome"}
                InputLabelProps={{shrink: true}}
              />
              <TextField
                error={!cognome || cognome === ''}
                autoComplete="off"
                disabled={isUtenteLoading}
                name={"cognome"}
                onChange={(event) => setCognome(event.currentTarget.value)}
                label={"Inserisci Cognome"}
                value={cognome}
                placeholder={"Inserisci Cognome"}
                InputLabelProps={{shrink: true}}
              />
            </>
          }
        </div>
        <FixedButtonPrenotazione
          text={"Salva"}
          disabled={!nome || nome === '' || !cognome || cognome === ''}
          version="blue"
          onClick={() => {
            handleSubmit(undefined)
          }}/>
      </form>
    </div>
  </section>
}

