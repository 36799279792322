import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import InfoIcon from '@mui/icons-material/Info';
import { Alert, Box, FormControl, FormControlLabel, InputLabel, MenuItem, Modal, Select, Skeleton } from "@mui/material";
import Switch from '@mui/material/Switch';
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { itIT } from '@mui/x-date-pickers/locales';
import { addMinutes, format, isSameDay } from "date-fns";
import { it } from 'date-fns/locale';
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Parcheggio } from "../../models/model";
import { TokenContext } from '../../providers/TokenProvider';
import { useCliente } from '../../services/api/cliente';
import { useParcheggiDisponibili } from '../../services/api/parcheggi';
import { useCreatePrenotazione, useDisponibilitaPrenotazioni, useHaDisponibilita } from '../../services/api/prenotazioni';
import { useTarghe } from '../../services/api/targhe';
import { useUtente } from '../../services/api/utente';
import FixedButtonPrenotazione from "../FixedButtonPrenotazione";
import FixedButtonDuplicaPrenotazione from "../FixedButtonDuplicaPrenotazione";
import ErroreDialog from '../shared/ErroreDialog';
import { PageTitle } from '../shared/PageTitle';
import ParkElement from '../shared/ParkElement';
import SquaredCard from '../shared/SquaredCard';
import ModalMap from './ModalMap';

export default function NuovaPrenotazione() {
  const navigate = useNavigate()
  const { token } = useContext(TokenContext);

  const {
    data: cliente,
    error: isClienteError,
    isLoading: isClienteLoading,
  } = useCliente()
  const expirationDate = useMemo(() => {
    const scadenzaContrattoCliente = cliente?.scadenza_contratto
    return scadenzaContrattoCliente ? new Date(scadenzaContrattoCliente) : undefined;
  }, [cliente]);

  const haDisponibilita = useHaDisponibilita();
  const {
    data: disponibilita,
    error: isDisponibilitaError,
    isLoading: isDisponibilitaLoading
  } = useDisponibilitaPrenotazioni()
  const isExpirationDateInAvailableReservationDates = useMemo(() => {
    if (expirationDate === undefined) {
      return false;
    }
    if (disponibilita) {
      const availableMinDate = disponibilita.minDate;
      const availableMaxDate = disponibilita.maxDate;
      return expirationDate > availableMinDate && expirationDate < availableMaxDate;
    }
  }, [disponibilita, expirationDate]);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  // const [minTimeToReserve, setMinTimeToReserve] = useState(new Date())
  const [date, setDate] = useState<Date | undefined>(undefined)
  const [from, setFrom] = useState<Date | undefined>(undefined)
  const [to, setTo] = useState<Date | undefined>(undefined)
  const [park, setPark] = useState<Parcheggio | undefined>(undefined)
  const [place, setplace] = useState<string | undefined>(undefined)
  const [stepValue, setStepValue] = useState<"date" | "day_piece" | "park" | "place" | "to_confirm" | "confirmed">("date")
  // const [isCurrentStepValid, setIsCurrentStepValid] = useState<boolean>(true)
  const [openLegenda, setOpenLegenda] = useState<boolean>(false)

  const [scegliTramiteMappa, setScegliTramiteMappa] = useState<boolean>(true)
  /* Flusso del controllo della sessione */
  useEffect(() => {
    const timeout = setTimeout(() => {
      setErrorMessage('Ops.. La sessione della prenotazione è scaduta.')
    }, 7 * 60 * 1000);
    return () => clearTimeout(timeout);
  }, []);

  const { hoursFromNow, minTimeToReserve } = useMemo(() => {
    if (!cliente?.n_ore_delay_prenotazione) {
      return {
        minTimeToReserve: new Date(),
        hoursFromNow: 0
      }
    }
    const minDaysForReserving = Math.trunc(cliente.n_ore_delay_prenotazione / 24); //trasformo le ore in giorni
    const hoursFromNow = cliente.n_ore_delay_prenotazione % 24;
    let localMinTimeToReserve = new Date()
    localMinTimeToReserve.setDate(localMinTimeToReserve.getDate() + minDaysForReserving);
    localMinTimeToReserve.setHours(localMinTimeToReserve.getHours() + hoursFromNow);
    return {
      minTimeToReserve: localMinTimeToReserve,
      hoursFromNow: hoursFromNow
    }
  }, [cliente?.n_ore_delay_prenotazione,])

  const { parkHalfDateTime, parkMinDateTime, parkMaxDateTime } = useMemo(() => {
    const currHour = new Date().getHours()
    const currMinutes = cliente?.durata_prenotazione !== 'ora' ? new Date().getMinutes() : 0
    if (date && minTimeToReserve) {
      if (isSameDay(date, minTimeToReserve)) {
        return {
          parkMinDateTime: new Date(minTimeToReserve?.getFullYear() || 0, minTimeToReserve?.getMonth() || 0, minTimeToReserve?.getDate(), (minTimeToReserve?.getHours() || 0), minTimeToReserve?.getMinutes()),
          parkHalfDateTime: new Date(minTimeToReserve?.getFullYear() || 0, minTimeToReserve?.getMonth() || 0, minTimeToReserve?.getDate(), (currHour + hoursFromNow) >= 13 ? (currHour + hoursFromNow) : 13, currMinutes),
          parkMaxDateTime: new Date(date?.getFullYear() || 0, date?.getMonth() || 0, date?.getDate(), 23, 59)
        }
      } else {
        return {
          parkMinDateTime: new Date(date?.getFullYear() || 0, date?.getMonth() || 0, date?.getDate(), 0, 0),
          parkHalfDateTime: new Date(date?.getFullYear() || 0, date?.getMonth() || 0, date?.getDate(), 13),
          parkMaxDateTime: new Date(date?.getFullYear() || 0, date?.getMonth() || 0, date?.getDate(), 23, 59)
        }
      }
    }
    return {
      parkMinDateTime: new Date(),
      parkHalfDateTime: new Date(),
      parkMaxDateTime: new Date()
    }
  }, [cliente?.durata_prenotazione, date, hoursFromNow, minTimeToReserve])
  let isCurrentStepValid = false;
  switch (stepValue) {
    case "date":
      if (date) {
        isCurrentStepValid = true;
      } else {
        isCurrentStepValid = false;
      }
      break;
    case "day_piece":
      if (date && !!from && !!to && from < to) {
        if (cliente?.durata_prenotazione === 'ora') {
          isCurrentStepValid = parkMinDateTime <= from

        } else {
          isCurrentStepValid = true;
        }
      } else {
        isCurrentStepValid = false;
      }
      break;
    case "park":
      if ((date && !!from && !!to && !!park) === true) {
        isCurrentStepValid = true;
      } else {
        isCurrentStepValid = false;
      }
      break;
    case "place":
      if (date && !!from && !!to && !!park && !!place) {
        isCurrentStepValid = true;
      } else {
        isCurrentStepValid = false;
      }
      break;
    default:
      isCurrentStepValid = false;
      break;
  }

  const fasciaOraria = useMemo(() => {
    if (cliente?.durata_prenotazione === 'ora') {
      return 'ora'
    } else if (cliente?.durata_prenotazione === 'mezzagiornata') {
      if (from?.getTime() === parkMinDateTime.getTime() && to?.getTime() === parkHalfDateTime.getTime()) {
        return 'mattina'
      } else {
        return 'pomeriggio'
      }
    } else {
      return 'giorno'
    }
  }, [cliente?.durata_prenotazione, from, parkHalfDateTime, parkMinDateTime, to])
  const {
    data: utente,
    error: isUtenteError,
    isLoading: isUtenteLoading,
  } = useUtente()

  const {
    data: parcheggi,
    error: isParcheggiError,
    isLoading: isParcheggiLoading,
  } = useParcheggiDisponibili( date as Date, fasciaOraria, from as Date, to as Date) //Sono sicuro che queste informazioni ci saranno al momento del fetch
  const {
    mutateAsync,
    isError: isErrorCreationPrenotazione,
    data: dataCreationPrenotazione,
    reset: resetCreationPrenotazione
  } = useCreatePrenotazione();
  useEffect(() => {
    resetCreationPrenotazione();
  }, [resetCreationPrenotazione]);
  useEffect(() => {
    if(haDisponibilita === false && !dataCreationPrenotazione) {
      setErrorMessage('Ops.. Dispnibilità terminate.')
    }
  }, [haDisponibilita, dataCreationPrenotazione]);

  const goNextStep = () => {
    switch (stepValue) {
      case "date":
        if (cliente?.durata_prenotazione === 'giorno') {
          if (minTimeToReserve.getTime() > parkMinDateTime.getTime()) {
            setFrom(minTimeToReserve)
          } else {
            setFrom(parkMinDateTime)
          }
          setTo(parkMaxDateTime)
          setStepValue("park")
        } else {
          setStepValue("day_piece")
        }
        break;
      case "day_piece":
        setStepValue("park")
        setPark(undefined)
        break;
      case "park":
        /* Aggiusto gli orari di apertura e di chiusura */

        if (cliente?.durata_prenotazione === "ora") {
          if (from && park && from < park?.parcheggio.ora_apertura) {
            setFrom(park?.parcheggio.ora_apertura)
          }
          if (to && park && to > park?.parcheggio.ora_chiusura) {
            setFrom(park?.parcheggio.ora_chiusura)
          }
        }
        if (park && park.scelta_posto && utente?.abilitato_scegli_posto) {
          setplace(undefined)
          setStepValue("place")
        } else {
          setStepValue("to_confirm")
        }
        break;
      case "place":
        setStepValue("to_confirm")
        break;
      case "to_confirm":
        /* faaccio effettivamento la mutation */

        let mutationObject: any = {
          data: format(date as Date, 'yyy-MM-dd'),
          fascia_oraria: fasciaOraria,
          lotti_parcheggio_id: park?.id as number,
          ora_ingresso: format(from as Date, 'HH:mm'),
          ora_uscita: format(to as Date, 'HH:mm')
        }
        if (utente?.abilitato_scegli_posto && place && park) {
          mutationObject.posti_codice = place
          mutationObject.lotti_parcheggio_id = park.postiDisponibili.find((el) => el.posti_codice === place)?.lotti_parcheggio_id
        }

        mutateAsync({
          accessToken: token,
          ...mutationObject
        }).catch((err) => {
          setErrorMessage(err?.message as string)
        })
          .then(() => {
            setStepValue("confirmed")
          })
        break;
      case "confirmed":
        navigate("/prenotazioni")
        break;
      default:
        break;
    }
  }
  const goPrevStep = () => {
    switch (stepValue) {
      case "day_piece":
        setStepValue("date")
        break;
      case "park":
        if (cliente?.durata_prenotazione === "mezzagiornata" || cliente?.durata_prenotazione === "ora") {
          setStepValue("day_piece")
        } else {
          setStepValue("date")
        }
        break;
      case "place":
        setStepValue("park")
        break;
      default:
        break;
    }
  }
  let currentStepPageTitle = undefined
  switch (stepValue) {
    case "date":
      currentStepPageTitle = "Data della prenotazione"
      break;
    case "day_piece":
      currentStepPageTitle = "Fascia Oraria"
      break;
    case "park":
      currentStepPageTitle = "Selezione del parcheggio"
      break;
    case "place":
      currentStepPageTitle = "Seleziona Posto"
      break;
    case "to_confirm":
      currentStepPageTitle = ""
      break;
    case "confirmed":
      currentStepPageTitle = ""
      break;
    default:
      break;
  }

  return <section>
    {stepValue === "date" &&
      <>
        <PageTitle
          show_back_button_on_mobile={true}
          title={currentStepPageTitle as unknown as string}
          back_link="/prenotazioni" />
          {!isClienteLoading && !isUtenteLoading && expirationDate !== undefined && isExpirationDateInAvailableReservationDates && (
            <Alert severity="warning" className="my-4">
            Non è possibile prenotare oltre la data {format(expirationDate, "dd-MM-yyy")}, la invitiamo a rivolgersi al suo Referente Master.
            </Alert>
          )}
      </>
    }
    {isUtenteError &&
      <ErroreDialog
        errorMessage="Errore durante il recupero delle informazioni dell'utente."
      />
    }
    {isClienteError &&
      <ErroreDialog
        errorMessage="Errore durante il recupero delle informazioni del cliente."
      />
    }
    {isParcheggiError &&
      <ErroreDialog
        errorMessage="Errore durante il recupero dei parcheggi."
      />
    }
    {isErrorCreationPrenotazione &&
      <ErroreDialog
        errorMessage="Errore durante la creazione della prenotazione."
      />
    }
    {isDisponibilitaError &&
      <ErroreDialog
        errorMessage="Errore durante il recupero delle disponibilità."
      />
    }
    {!isUtenteError && !isUtenteLoading && !isClienteError && !isClienteLoading && !isDisponibilitaError && !isDisponibilitaLoading && disponibilita &&
      <>
        {currentStepPageTitle && stepValue !== "date" && date &&
          <div className="flex flex-col md:flex-row justify-start md:justify-between  items-start md:items-center text-start">
            <div className="page-title-container">
              <button onClick={() => { goPrevStep() }} >
                <ChevronLeftIcon />
              </button>
              <h1>{currentStepPageTitle as unknown as string}</h1>
            </div>
            <h2 className='mt-4 md:mt-0 max-w-full md:max-w-[50%] text-end'>Data prenotazione {format(date, "dd-MM-yyy")}</h2>
          </div>
        }
        <div className="mt-7">
          {stepValue === 'date' && (
            <>
              {isUtenteLoading &&
                <Skeleton height={48} />
              }
              {!isUtenteLoading && utente &&
                <LocalizationProvider
                  localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                  adapterLocale={it}
                  dateAdapter={AdapterDateFns}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      className="w-full"
                      defaultValue={date}
                      format='dd/MM/yyyy'
                      shouldDisableDate={(date) => {
                        return disponibilita.notAvailable?.filter((el) => {
                          return isSameDay(date, el)
                        }).length > 0
                      }}
                      onChange={(e) => {
                        if (e !== null) {
                          const selectedDate = e
                          selectedDate.setHours(23)
                          selectedDate.setMinutes(59)
                          setDate(selectedDate)
                        }
                      }}
                      minDate={disponibilita.minDate}
                      maxDate={disponibilita.maxDate}
                      label="Data della prenotazione"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              }
            </>
          )}
          {stepValue === 'day_piece' && (
            <>
              {cliente?.durata_prenotazione === "ora" &&
                <>
                  <LocalizationProvider
                    localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                    dateAdapter={AdapterDateFns}>
                    <DemoContainer components={['TimePicker']}>
                      <TimePicker
                        ampm={false}
                        value={from}
                        onChange={(e) => { if (e !== null) setFrom(e) }}
                        minTime={parkMinDateTime}
                        maxTime={(to !== null || to !== undefined) ? addMinutes(to as Date, -1) : null}
                        label="Dalle:" />
                    </DemoContainer>
                  </LocalizationProvider>
                  <LocalizationProvider
                    localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                    dateAdapter={AdapterDateFns}>
                    <DemoContainer components={['TimePicker']}>
                      <TimePicker
                        ampm={false}
                        value={to}
                        disabled={!from}
                        onChange={(e) => { if (e !== null) setTo(e) }}
                        minTime={(from !== null || from !== undefined) ? addMinutes(from as Date, 1) : null}
                        maxTime={parkMaxDateTime}
                        label={`Alle: ${!from ? "(Inserisci prima l'ora di inizio)" : ''}`} />
                    </DemoContainer>
                  </LocalizationProvider>
                </>
              }
              {cliente?.durata_prenotazione === "mezzagiornata" &&
                <>
                  {/*
                  Non mostro la fascia oraria mattina se la data della prenotazione è oggi e se nel momento in cui si stà prenotando è il pomeriggio.
                  */}
                  <SquaredCard
                    className="w-full !h-16"
                    label="Mattina"
                    disabled={(() => {
                      let disabledMonrningRange = false
                      if (parkHalfDateTime.getTime() < minTimeToReserve.getTime()) {
                        disabledMonrningRange = true
                      }
                      return disabledMonrningRange
                    })()} /* Disabilitata quando la fine della mattinata per quella data è minore momento da cui l'utente può prenotare */
                    selected={from?.getTime() === parkMinDateTime.getTime() && to?.getTime() === parkHalfDateTime.getTime()}
                    onClick={() => {
                      setFrom(parkMinDateTime)
                      setTo(parkHalfDateTime)
                    }} />
                  <SquaredCard
                    className="w-full mt-4 !h-16"
                    label="Pomeriggio"
                    disabled={false}
                    selected={from?.getTime() === parkHalfDateTime.getTime() && to?.getTime() === parkMaxDateTime.getTime()} onClick={() => {
                      setFrom(parkHalfDateTime)
                      setTo(parkMaxDateTime)
                    }} />
                </>
              }
              <div className="mt-4">
                <div className="information-container">
                  <InfoIcon />
                  <p>Attenzione... L'orario della prenotazione potrebbe essere modificata dal sistema in base a l'apertura del parcheggio.</p>
                </div>
              </div>
            </>
          )}
          {stepValue === 'park' && (
            <ol>
              {cliente?.durata_prenotazione === 'ora' && (
                <li>
                  <div className="my-4">
                    <div className="information-container">
                      <InfoIcon />
                      <p>Attenzione... Un parcheggio potrebbe risultare non disponibile se l'orario di apertura è successivo a quello indicato, oppure se
                        quello di chiusura è antecedente da quello indicato.
                      </p>
                    </div>
                  </div>
                </li>
              )}
              <li>
                {date && utente && cliente && !isParcheggiLoading && !isParcheggiError && parcheggi && parcheggi.filter((el) => utente.lotti_parcheggio_default_id === el.id).map((el, index) => {
                  return (
                    <ParkElement
                      date={date}
                      from={from}
                      to={to}
                      className={`${index < parcheggi.length - 1 ? "mb-4" : ""}`}
                      label={el.nome_parcheggio}
                      selected={el.id === park?.id}
                      parcheggio={el}
                      utente={utente}
                      cliente={cliente}
                      onClick={() => {
                        setPark(el.id !== park?.id ? el : undefined)
                      }
                      }
                    />
                  )
                })}
                <h3 className="mt-4">Tutti i parcheggi</h3>
                <div className="mt-4 mb-44">
                  <>
                    {isParcheggiLoading &&
                      <div className="flex flex-col">
                        <Skeleton className="!scale-1" height={170}></Skeleton>
                        <Skeleton className="mt-4 !scale-1" height={170}></Skeleton>
                        <Skeleton className="mt-4 !scale-1" height={170}></Skeleton>
                        <Skeleton className="mt-4 !scale-1" height={170}></Skeleton>
                      </div>
                    }
                    {date && utente && cliente && !isParcheggiLoading && !isParcheggiError && parcheggi && parcheggi.filter((el) => utente.lotti_parcheggio_default_id !== el.id).map((el, index) => {
                      return (
                        <div key={el.parcheggi_id}>
                          <ParkElement
                            date={date}
                            from={from}
                            to={to}
                            className={`${index < parcheggi.length - 1 ? "mb-4" : ""}`}
                            label={el.nome_parcheggio}
                            selected={el.id === park?.id}
                            parcheggio={el}
                            utente={utente}
                            cliente={cliente}
                            onClick={() => {
                              setPark(el.id !== park?.id ? el : undefined)
                            }
                            }
                          />
                        </div>
                      )
                    })}
                  </>
                </div>
              </li>
            </ol>
          )}
          {stepValue === 'place' && park && (
            <div className="flex flex-col">
              {park.mappa_path &&
                <div className="flex items-center justify-between text-blue-500">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={scegliTramiteMappa}
                        onChange={(e: any) => { setScegliTramiteMappa(e.target.checked) }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    } label="Strumento mappa" />
                  {!!place &&
                    <p className='text-center font-bold  uppercase text-green-500 underline'>Cod. posto selezionato {place}</p>
                  }
                  {!place &&
                    <p className='text-center font-bold uppercase text-red-500 underline'>nessun posto selezionato</p>
                  }
                </div>
              }
              {(!park.mappa_path || (park.mappa_path && !scegliTramiteMappa)) &&
                <FormControl className='mt-5' fullWidth>
                  <InputLabel id="demo-simple-select-label">Posto Auto</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={place}
                    label="Posto Auto"
                    onChange={(e) => {
                      setplace(e.target.value)
                    }}
                  >
                    {park?.postiDisponibili.length === 0 &&
                      <MenuItem disabled={true}>Non si sono porti disponibili</MenuItem>
                    }
                    {park?.postiDisponibili.map((el) => {
                      return <MenuItem onClick={() => {
                        setplace(el.posti_codice)
                      }} value={el.posti_codice}>{el.posti_codice}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              }
              {(park.mappa_path && scegliTramiteMappa) &&
                <div className="apri-mappa-container">
                  <>
                    {park.mappa_path &&
                      <div className='flex flex-col w-full'>
                        {openLegenda === false &&
                          <div className="relative flex justify-between text-blue-500 underline">
                            <button onClick={() => { setOpenLegenda(true) }} className="legenda-button">
                              <InfoIcon />
                              <strong>Visualizza legenda</strong>
                            </button>
                          </div>
                        }
                        <ShowLegenda open={openLegenda} handleClose={() => { setOpenLegenda(false) }} />
                        <div className={!scegliTramiteMappa ? 'hidden' : ''}>
                          <ModalMap
                            selected={(e: string) => {
                              setplace(e)
                            }}
                            posti_codice={place}
                            allPlaces={park?.posti || []}
                            availablePlaces={park?.postiDisponibili || []}
                            url={`${park.mappa_path}`}
                            onClose={() => { setOpenLegenda(false) }} />
                        </div>
                      </div>
                    }
                  </>
                </div>
              }
            </div>
          )}
          {(stepValue === 'to_confirm' || stepValue === 'confirmed') && date && park && from && to && !isErrorCreationPrenotazione && (
            <>
              <PrenotazioneDialog
                onConfirm={goNextStep}
                type={stepValue}
                date={date as Date}
                park={park}
                from={from}
                to={to}
                prenotazioneResult={dataCreationPrenotazione}
                place={park.postiDisponibili.find((el) => el.posti_codice === place)?.posti_codice || undefined}
              />
            </>
          )}
        </div>
        {(stepValue === "date" || stepValue === "day_piece" || stepValue === "park" || stepValue === "place") &&
          <FixedButtonPrenotazione
            key="fixedButtonPrenotazione"
            text="Avanti"
            disabled={!isCurrentStepValid}
            version="blue"
            onClick={() => { goNextStep() }} />
        }
        {errorMessage &&
          <ErroreDialog errorMessage={errorMessage}></ErroreDialog>
        }
      </>
    }
  </section>
}

function PrenotazioneDialog({
  type,
  date,
  from,
  to,
  park,
  place,
  onConfirm,
  prenotazioneResult
}: {
  type: "to_confirm" | "confirmed",
  date: Date,
  from: Date,
  to: Date,
  park: Parcheggio,
  place?: string,
  onConfirm: () => void,
  prenotazioneResult?: {
    id: string;
}
}) {
  const navigate = useNavigate();
  const {
    data: cliente,
  } = useCliente()
  const {
    data: targhe,
  } = useTarghe()
  const {
    data: utente,
    error: isUtenteError,
    isLoading: isUtenteLoading,
  } = useUtente()
  const parkMinDateTime = new Date(0, 0, 0, 8)
  const parkHalfDateTime = new Date(0, 0, 0, 0)
  parkHalfDateTime.toLocaleString('it-IT', {
    hour12: false,
  })
  parkHalfDateTime.setHours(13)
  const parkMaxDateTime = new Date(0, 0, 0, 18, 45)
  parkMaxDateTime.toLocaleString('it-IT', {
    hour12: false,
  })
  parkMaxDateTime.setHours(18)
  parkMaxDateTime.setMinutes(45)
  const getFasciaOraria = useCallback(() => {
    if (cliente?.durata_prenotazione === 'ora') {
      return 'ora'
    } else if (cliente?.durata_prenotazione === 'mezzagiornata') {
      if (from?.getTime() === parkMinDateTime.getTime() && to?.getTime() === parkHalfDateTime.getTime()) {
        return 'mattina'
      } else {
        return 'pomeriggio'
      }
    } else {
      return 'giorno'
    }
  }, [cliente?.durata_prenotazione, from, parkHalfDateTime, parkMinDateTime, to])
  
  return <div className="prenotazione-dialog-container">
    <ol>
      <li>
        <img src={`${process.env.PUBLIC_URL}/${type}-icon.svg`} alt={type} />
      </li>
      <li className="mt-10">
        <h1>{type === "to_confirm" ? "Ci siamo quasi..." : "Perfetto!"}</h1>
      </li>
      <li className="mt-4">
        {type === "to_confirm" &&
          <p className="prenotazione-dialog-informations">Riassunto della tua prenotazione:
            <b> <h3>{format(date, "dd-MM-yyy")}</h3> </b>
            alla fascia oraria
            <b> <h3>{getFasciaOraria()}</h3> </b>
            <b> <h3>({`${format(from, "HH:mm")} - ${format(to, "HH:mm")}`})</h3> </b>
            al  parcheggio
            <b> <h3>{park.parcheggio.nome_parcheggio}</h3></b>
            lotto
            <b> <h3>{park.nome_parcheggio} {(utente?.abilitato_scegli_posto === true && place) && `posto ${place}`}</h3></b>
            targa
            <b> <h3>{targhe?.find((el) => el.targa_abilitata === true)!.targa}</h3></b>
            Se i dati sono corretti allora clicca sul pulsante per confermare.
            {park.scelta_posto === false && utente?.abilitato_scegli_posto === true &&
              <b> La scelta posto è disabilitata.</b>
            }</p>
        }
        {type === "confirmed" &&
          <p className="prenotazione-dialog-informations">La tua prenotazione per il giorno:
            <b> <h3>{format(date, "dd-MM-yyy")}</h3> </b>
            alla fascia oraria
            <b> <h3>{getFasciaOraria() === 'ora' ? `${format(from, "HH:mm")} - ${format(to, "HH:mm")}` : getFasciaOraria()}</h3> </b>
            {park.scelta_posto === true &&
              <>
                al parcheggio
                <b> <h3>{park.parcheggio.nome_parcheggio}</h3></b>
                lotto
                <b> <h3>{park.nome_parcheggio} {(utente?.abilitato_scegli_posto === true && place) && `posto ${place}`}</h3></b>
                targa
                <b> <h3>{targhe?.find((el) => el.targa_abilitata === true)!.targa}</h3></b>
              </>
            }
            è andata a buon fine! </p>
        }
      </li>
      <li>
      { utente?.abilitato_duplica_prenotazione === true && type === "confirmed" && prenotazioneResult && (
        <FixedButtonDuplicaPrenotazione
          key="button-duplica-prenotazione"
          onClick={() => navigate(`/prenotazioni/${prenotazioneResult}/duplica`)}
          version="white"
          disabled={false}
          text="Duplica prenotazione in altre date" />
      )}
        <FixedButtonPrenotazione
          onClick={onConfirm}
          version="white"
          disabled={false}
          text={type === "to_confirm" ? "Conferma la prenotazione" : "Torna alle prenotazioni"} />
      </li>
    </ol>
  </div>
}

export function ShowLegenda(props: { open: boolean, handleClose: () => void }) {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,

  };
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      keepMounted
    >
      <Box sx={style} className="w-[80vw] md:w-auto">
        <h2>Utilizzo della mappa per la scelta posto</h2>
        <p className='mt-5'>La mappa ti permette di selezionare il posto auto in maniera visuale.</p>
        <p className='mt-2'>I posti disponibili sono colorati di <strong style={{ color: "lime" }}>verde</strong>, quelli non disponibili sono colorati di <strong style={{ color: "red" }}>rosso</strong> e quelli a cui non hai accesso sono di colore <strong style={{ color: "#727272" }}>grigio</strong>.</p>
        <p className='mt-2'>Selezionando un posto, questo verrà evidenziato in <strong style={{ color: "#185a7d" }}>azzurro</strong>.</p>
        <p className='mt-2'>Per interagire con la mappa:
          <ul>
            <li>- Da <strong>dispositivi desktop</strong> è possibile ingradire la mappa utilizzando il "doppio click" o attraverso la rotella del mouse. E' anche possibile muovere la mappa tenendo prenuto il
              tasto sinistro del mouse e trascinandola.</li>
            <li>- Da <strong>dispositivi mobile</strong> è possibile ingrandire la mappa utilizzando il "doppio tap" o ingrandendola come una normale immagine. E' anche possibile muovere la mappa trascinandola.</li>
          </ul>
        </p>
        <p className='mt-2'>Per <strong>selezionare un posto auto</strong> basta cliccare tra uno di quelli di colore <strong style={{ color: "lime" }}>verde</strong>. Se la selezione è andata a buon fine il posto auto diventerà di colore <strong style={{ color: "#185a7d" }}>azzurro</strong>.</p>
        <div className="flex justify-end mt-5">
          <button className='big-button button-third blue-version !w-fit' onClick={props.handleClose}>Chiudi</button>
        </div>
      </Box>
    </Modal>
  )
}