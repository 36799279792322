import InfoIcon from "@mui/icons-material/Info";
import { Skeleton } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Targa } from "../../models/model";
import { TokenContext } from "../../providers/TokenProvider";
import { useEnableTarga, useTarghe } from "../../services/api/targhe";
import FixedButtonDuplicaPrenotazione from "../FixedButtonDuplicaPrenotazione";
import FixedButtonPrenotazione from "../FixedButtonPrenotazione";
import ErroreDialog from "../shared/ErroreDialog";
import { PageTitle } from "../shared/PageTitle";
import { useUtente } from "../../services/api/utente";

export default function Targhe() {
  const navigate = useNavigate();

  const [selectedTarga, setSelectedTarga] = useState<Targa | undefined>(undefined);

  const { data: targhe, error, isFetching } = useTarghe();

  const targaAttiva = useMemo(() => targhe?.find((el) => el.targa_abilitata === true), [targhe]);
  const { token } = useContext(TokenContext);

  const { data: utente, error: isUtenteError, isLoading: isUtenteLoading } = useUtente();

  const [showConfirmAggiornaTargaPrenotazioni, setShowConfirmAggiornaTargaPrenotazioni] = useState(false);

  const {
    mutate: mutateEnableTarga,
    isPending: isPendingEnableTarga,
    isSuccess: isSuccessEnableTarga,
    error: errorEnableTarga,
    data: targaAttivataId,
  } = useEnableTarga();
  const targaAttivata = useMemo(() => targhe?.find((el) => el.id === targaAttivataId), [targaAttivataId, targhe]);
  const attivaTarga = () => {
    mutateEnableTarga({
      id: selectedTarga?.id as number,
      accessToken: token as string,
    });
  };

  useEffect(() => {
    const targaAbilitata = targhe?.find((el) => el.targa_abilitata === true);
    if (targaAbilitata) {
      setSelectedTarga(targaAbilitata);
    }
  }, [isFetching, targhe]);
  const creaNuovaTarga = () => {
    navigate("new");
  };

  const canUserRegisterNewPlate = useMemo(() => {
    if (utente !== undefined && targhe !== undefined) {
      return (!utente?.abilitato_duplica_prenotazione && targhe.length < 2) || (utente?.abilitato_duplica_prenotazione && targhe.length < 1);
    }
  }, [utente, targhe]);

  return (
    <section>
      <PageTitle show_back_button_on_mobile={true} title="Targhe" />
      <div className="mt-7">
        {errorEnableTarga && <ErroreDialog errorMessage={(errorEnableTarga?.message as string) || "Errore durante l'attivazione della targa"} />}
        {error && <ErroreDialog />}
        {isFetching && (
          <>
            <Skeleton variant="rounded" height={48} />
            <div className="flex justify-between mt-4">
              <Skeleton className="" variant="rounded" height={90} width="48%" />
              <Skeleton variant="rounded" height={90} width="48%" />
            </div>
          </>
        )}
        {!isFetching && !error && targhe && (
          <>
            <button
              className={`big-button button-secondary ${!canUserRegisterNewPlate ? "disabled" : ""}`}
              onClick={() => {
                if (canUserRegisterNewPlate) {
                  creaNuovaTarga();
                }
              }}
            >
              Registra nuova
            </button>
            {targhe?.length === 0 && <p className="no-data">Non ci sono taghe attive.</p>}
            <ol className="card-container">
              {targhe && !utente?.abilitato_duplica_prenotazione &&
                targhe?.map((el: Targa) => {
                  return (
                    <>
                      <SingolaTarga
                        targa={el}
                        selected={el.id === selectedTarga?.id}
                        disabled={isFetching || isPendingEnableTarga}
                        onClick={() => {
                          setSelectedTarga(el);
                        }}
                      />
                    </>
                  );
                })
              }
              {targhe && utente?.abilitato_duplica_prenotazione && targaAttiva && (
                <>
                  <SingolaTarga
                    targa={targaAttiva}
                    selected={targaAttiva.id === selectedTarga?.id}
                    disabled={isFetching || isPendingEnableTarga}
                    onClick={() => {
                      setSelectedTarga(targaAttiva);
                    }}
                  />
                </>
              )}
            </ol>
            <div className="mt-4">
              <div className="information-container">
                <InfoIcon />
                {!utente?.abilitato_duplica_prenotazione && (
                  <p>
                    <strong>Attenzione!</strong> La targa con la dicitura "Attiva" sarà utilizzata quando effettui le prenotazioni.
                    <br />
                    Per attivare un'altra targa, selezionala e clicca su "Attiva".
                    <br />
                    Per modificare una targa, selezionala e clicca su "Modifica".
                  </p>
                )}
                {utente?.abilitato_duplica_prenotazione && (
                  <p>
                    <strong>Attenzione!</strong> La targa con la dicitura "Attiva" sarà utilizzata quando effettui le prenotazioni.
                    <br />
 {/*                    {targhe.length > 1 && (
                      <span>Per attivare un'altra targa, selezionala e clicca su "Attiva".
                      <br />
                      Quando attivi un'altra targa tra quelle registrate, ti verrà chiesto se aggiornare la targa appena attivata per le prenotazioni future già effettuate.</span>
                    )} */}
                    Per modificare una targa, selezionala e clicca su "Modifica".
                    <span> Quando modifichi una targa, ti verrà chiesto se aggiornare le prenotazioni future già effettuate con la targa modificata.</span>
                  </p>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {showConfirmAggiornaTargaPrenotazioni && (
        <div className="prenotazione-dialog-container">
          <ol>
            <li>
              <img src={`${process.env.PUBLIC_URL}/${"to_confirm"}-icon.svg`} alt={"to_confirm"} />
            </li>
            <li className="mt-4">
              <h1>Ci siamo quasi...</h1>
            </li>
            <li className="mt-4">
              <p className="prenotazione-dialog-informations">
                <span>
                  <strong>Attenzione!</strong> Questa targa è valida per per prenotare da questo momento in poi, ma non per le prenotazioni
                  attive future che potresti aver già effettuato in precedenza.
                  <h3>Vuoi utilizzare questa targa per le tue prenotazioni attive future già effettuate?</h3>
                </span>
              </p>
              <FixedButtonDuplicaPrenotazione
                onClick={() => navigate(`/targhe/${selectedTarga?.id}/aggiorna-prenotazioni-future/attiva`)}
                version="white"
                text={"Si"}
                disabled={isPendingEnableTarga}
              />
              <FixedButtonPrenotazione onClick={() => attivaTarga()} disabled={isPendingEnableTarga} version="white" text={"No"} />
            </li>
          </ol>
        </div>
      )}
      {isSuccessEnableTarga && targaAttivata && (
        <div className="prenotazione-dialog-container">
          <ol>
            <li>
              <img src={`${process.env.PUBLIC_URL}/${"confirmed"}-icon.svg`} alt={"confirmed"} />
            </li>
            <li className="mt-10">
              <h1>Perfetto!</h1>
            </li>
            <li className="mt-4">
              <p className="prenotazione-dialog-informations">
                <h3>Hai attivato la targa {targaAttivata.targa}</h3>
              </p>
            </li>
            <FixedButtonPrenotazione onClick={() => navigate("/prenotazioni")} version="white" disabled={false} text={"Torna alle prenotazioni"} />
          </ol>
        </div>
      )}

      {!isFetching && !error && targhe && targhe.length > 1 && selectedTarga && targaAttiva && selectedTarga?.id !== targaAttiva?.id && (
        <FixedButtonDuplicaPrenotazione
          text="Attiva"
          version="blue"
          onClick={() => {
            utente?.abilitato_duplica_prenotazione ? setShowConfirmAggiornaTargaPrenotazioni(true) : attivaTarga();
          }}
          disabled={isFetching || !!error || isPendingEnableTarga || selectedTarga?.id === targaAttiva?.id}
        />
      )}

      {selectedTarga && (
        <FixedButtonPrenotazione
          text="Modifica"
          disabled={isFetching || isPendingEnableTarga}
          version="white"
          onClick={() => {
            navigate(`/targhe/${selectedTarga.id}`);
          }}
        />
      )}
    </section>
  );
}

function SingolaTarga({ targa, selected, onClick, disabled }: { targa: any; selected: boolean; onClick: () => void; disabled: boolean }) {
  return (
    <button
      disabled={disabled}
      onClick={() => {
        if (!targa.selected) {
          onClick();
        }
      }}
      className={`card ${selected ? "active" : ""}`}
    >
      <h2>{targa.targa}</h2>
      <h3>{targa.nota}</h3>
      {targa.targa_abilitata === true && <p>Attiva</p>}
    </button>
  );
}
