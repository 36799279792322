import { Route, Routes } from "react-router-dom";
import { useCliente } from "../services/api/cliente";
import BottomMenu from './BottomMenu';
import Home from './Home';
import NuovaPrenotazione from './Prenotazioni/NuovaPrenotazione';
import Prenotazione from "./Prenotazioni/Prenotazione";
import Prenotazioni from './Prenotazioni/Prenotazioni';
import Aiuto from './Profilo/Aiuto';
import InformazioniPersonali from './Profilo/InformazioniPersonali';
import NuovaTarga from './Profilo/NuovaTarga';
import Privacy from "./Profilo/Privacy";
import Profilo from './Profilo/Profilo';
import Targhe from './Profilo/Targhe';
import DuplicaPrenotazione from "./Prenotazioni/DuplicaPrenotazione";
import AttivaTargaPrenotazioniFuture from "./Prenotazioni/AttivaTargaPrenotazioniFuture";
import AggiornaTargaPrenotazioniFuture from "./Prenotazioni/AggiornaTargaPrenotazioniFuture";

export function Layout() {

    const {
        data: cliente,
    } = useCliente()
    return (
        <div className='container mx-auto flex flex-col' >
            <div className="p-8" id="app-container">
                <header className='flex justify-between items-center'>
                    <img className="header-image" src={`${process.env.PUBLIC_URL}/Milanofiori-tagline-Main-logo-H-N.png`} alt="Milanofiori-tagline-Main-logo-H-N" />
                    <img className="header-image" src={`${cliente?.logo_azienda}`} alt="Milanofiori-tagline-Main-logo-Azienda-Cliente" />
                </header>
                <main className='mt-4'>

                    <Routes >
                        <Route path='' index element={<Home />} />
                        <Route path='prenotazioni' index element={<Prenotazioni />} />
                        <Route path='prenotazioni/new' index element={<NuovaPrenotazione />} />
                        <Route path='prenotazioni/:id' index element={<Prenotazione />} />
                        <Route path='prenotazioni/:id/duplica' index element={<DuplicaPrenotazione />} />
                        <Route path='profilo' index element={<Profilo />} />
                        <Route path='targhe' index element={<Targhe />} />
                        <Route path='targhe/:id' index element={<NuovaTarga />} />
                        <Route path='targhe/:id/aggiorna-prenotazioni-future/modifica' index element={<AggiornaTargaPrenotazioniFuture />} />
                        <Route path='targhe/:id/aggiorna-prenotazioni-future/attiva' index element={<AttivaTargaPrenotazioniFuture />} />
                        <Route path='informazioni-personali' index element={<InformazioniPersonali />} />
                        <Route path='aiuto' index element={<Aiuto />} />
                        <Route path='privacy' index element={<Privacy />} />
                    </Routes>
                </main>
            </div>
            <BottomMenu />
        </div>
    );
}
