import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Parcheggio } from '../../models/model';

export default function ModalMap({
  allPlaces,
  availablePlaces,
  url,
  posti_codice,
  selected
}: {
  allPlaces: Parcheggio['postiDisponibili']
  availablePlaces: Parcheggio['postiDisponibili']
  url: string,
  posti_codice?: string,
  onClose: () => void
  selected: (posti_parcheggi_id: string) => void
}) {
  const ref = useRef(null)

  const { styleDisp, styleOcc } = useMemo(() => {
    const dispSelectors: string[] = [];
    const occSelectors: string[] = [];
    for (const one of allPlaces) {
      const selector = `svg g#layer_pulsanti #Pn-${one.posti_codice}`
      if (availablePlaces.find((el) => el.posti_codice === one.posti_codice)) {
        dispSelectors.push(selector);
      } else {
        occSelectors.push(selector);
      }
    }
    const styleDisp = dispSelectors.join(', ') + '{fill: lime !important; cursor: pointer;}'
    const styleOcc = occSelectors.join(', ') + '{fill: red !important;}'
    return {
      styleDisp,
      styleOcc
    }
  }, [allPlaces, availablePlaces]);
  const styleSelected = `svg g#layer_pulsanti #Pn-${posti_codice} { fill: #185a7d !important; }`

  const handleClick = (event: any) => {
    /* Recuperare l'attributo id sapendo che  */
    const regex = /^Pn-/;
    if (regex.test(event?.target?.id)) {
      const id = event.target.id.split('-')[1]
      const finded = availablePlaces.find((el) => el.posti_codice === id);
      if (finded) {
        selected(finded.posti_codice)
      }
    }
  }
  const { data, isFetching, isError } = useQuery<any>({
    queryKey: ['mappa'],
    queryFn: async () => {

      return fetch(url)
        .then(res => {
          /* La risposta torna un svg.. Come faccio a recuperare il contenuto? */
          return res.text() as Promise<any>
        })
    }
  })
  const { height, width } = useWindowDimensions();

  // return (
  //   <>
  //     <style>
  //       {styleDisp}
  //       {styleOcc}
  //       {styleSelected}
  //     </style>
  //     <div ref={ref}>
  //       <ReactSvgPanZoomLoader src={url}

  //         render={(content) => {
  //           return (
  //             <UncontrolledReactSVGPanZoom className=" !max-w-[100%]" width={width} height={height} onClick={handleClick}>
  //               <svg width={width} height={height} >
  //                 {content}
  //               </svg>
  //             </UncontrolledReactSVGPanZoom>
  //           )
  //         }} />
  //     </div>
  //   </>
  // )
  /* GEstisci il  */
  return (
    <>
      {data &&
        <>
          <style>

            {styleDisp}
            {styleOcc}
            {styleSelected}
          </style>
          <div ref={ref}
            className='!-mx-8'>
            <TransformWrapper
              alignmentAnimation={{
                disabled: true
              }}
              centerOnInit={true}
            >
              <TransformComponent  >
                <div className="mappa-wrapper" style={{ width, height: "80vh" }} onClick={handleClick} dangerouslySetInnerHTML={{ __html: data }} />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </>
      }
    </>
  )

}
function getWindowDimensions() {
  const element = document.getElementById('app-container') as HTMLElement;
  const { paddingLeft, paddingRight } = getComputedStyle(element)
  const { offsetWidth: width, offsetHeight: height } = element;
  // const { offsetWidth: width, offsetHeight: height } = element;
  return {
    width: width,
    height: height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
