import { useNavigate } from "react-router-dom"
import FixedButtonPrenotazione from "../FixedButtonPrenotazione"

export default function SuccesDialog({
  successMessage
}: {
  successMessage?: string
}) {
  const navigate = useNavigate()
  return <div className="success-dialog-container">

    <ol>
      <li>
      <img src={`${process.env.PUBLIC_URL}/confirmed-icon.svg`} alt="confirmed-icon" />
      </li>
      <li className="mt-10">
        <h1>Perfetto! Operazione effettuata con successo!</h1>
      </li>
      <li className="mt-4">
        {successMessage &&
          <p className="successe-dialog-informations">{successMessage}</p>
        }
      </li>
      <li>
        <FixedButtonPrenotazione
          onClick={()=>{navigate(-1)}}
          version="white"
          disabled={false}
          text={"Torna indietro"} />
      </li>
    </ol>
  </div>
}