import { NavLink } from "react-router-dom";

export default function BottomMenu() {
  return <div className="parcheggio-milano-fiori-nord-bottom-menu parcheggio-milano-fiori-nord-tc-185a7d">
    <nav className="flex justify-evenly py-5" id="bottom-menu">
      <NavLink to="/" className="flex flex-col items-center justify-center  min-w-[60px] min-h-[45px] ">
        <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M7.4824 5.8035C7.4504 5.8035 7.4184 5.8075 7.3864 5.8155C4.4164 6.5465 2.0004 9.7645 2.0004 12.9885C2.0004 17.0445 5.3004 20.3445 9.3574 20.3445C13.0144 20.3445 16.0704 17.7395 16.6244 14.1495C16.6284 14.1195 16.6424 14.0325 16.5604 13.9355C16.4824 13.8445 16.3584 13.7905 16.2284 13.7905C14.8174 13.7905 13.7404 13.8225 12.8994 13.8465C10.8634 13.9075 10.0214 13.9305 9.1894 13.3135C7.9354 12.3845 7.8294 10.7885 7.8294 6.0945C7.8294 6.0105 7.7934 5.9405 7.7214 5.8845C7.6544 5.8315 7.5694 5.8035 7.4824 5.8035ZM9.3574 21.8445C4.4734 21.8445 0.500401 17.8715 0.500401 12.9885C0.500401 9.1165 3.4284 5.2445 7.0274 4.3585C7.5894 4.2215 8.1934 4.3495 8.6474 4.7035C9.0804 5.0435 9.3294 5.5505 9.3294 6.0945C9.3294 10.4795 9.4774 11.6595 10.0824 12.1085C10.4794 12.4015 11.0234 12.3985 12.8564 12.3475C13.7084 12.3225 14.7994 12.2905 16.2284 12.2905C16.7984 12.2905 17.3354 12.5345 17.6994 12.9595C18.0374 13.3545 18.1854 13.8705 18.1074 14.3785C17.4394 18.7035 13.7594 21.8445 9.3574 21.8445Z" fill="#252727" />
          <mask id="mask0_53_319" maskUnits="userSpaceOnUse" x="10" y="0" width="12" height="11">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.974 0.500488H21.393V10.7898H10.974V0.500488Z" fill="white" />
          </mask>
          <g mask="url(#mask0_53_319)">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.5108 2.00174C12.4008 4.52074 12.5638 7.77474 12.6398 9.06374C12.6438 9.14074 12.6998 9.19674 12.7758 9.20074C13.8038 9.25974 17.3458 9.42374 19.8928 9.04874C19.8988 7.64474 18.9378 5.74074 17.4908 4.29474C16.0068 2.81274 14.2508 2.00174 12.5328 2.00174H12.5108ZM15.8148 10.7897C14.5048 10.7897 13.3518 10.7367 12.6888 10.6987C11.8548 10.6497 11.1908 9.98474 11.1428 9.15074C11.0648 7.82874 10.8958 4.46974 11.0158 1.87174C11.0488 1.11674 11.6588 0.515742 12.4048 0.502742C14.5418 0.440742 16.7468 1.43274 18.5508 3.23374C20.3088 4.99074 21.4248 7.29974 21.3928 9.11674C21.3808 9.82574 20.8658 10.4177 20.1698 10.5227C18.8118 10.7277 17.2218 10.7897 15.8148 10.7897Z" fill="#252727" />
          </g>
        </svg>
        <strong className="hidden">Home</strong>
      </NavLink>
      <NavLink to="/prenotazioni" className="flex flex-col items-center justify-center  min-w-[60px] min-h-[45px]">
        <svg width="21" height="23" viewBox="0 0 21 23" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 9.5C1.5 5.729 1.5 3.843 2.672 2.672C3.843 1.5 5.729 1.5 9.5 1.5H11.5C15.271 1.5 17.157 1.5 18.328 2.672C19.5 3.843 19.5 5.729 19.5 9.5V13.5C19.5 17.271 19.5 19.157 18.328 20.328C17.157 21.5 15.271 21.5 11.5 21.5H9.5C5.729 21.5 3.843 21.5 2.672 20.328C1.5 19.157 1.5 17.271 1.5 13.5V9.5Z" stroke="black" strokeWidth="1.5" />
          <path d="M6.5 9.5H14.5M6.5 13.5H11.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
        <strong className="hidden">Prenotazioni</strong>
      </NavLink>
      <NavLink to="/profilo" className="flex flex-col items-center justify-center  min-w-[60px] min-h-[45px] ">
        <svg width="17" height="21" viewBox="0 0 17 21" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_53_224" maskUnits="userSpaceOnUse" x="0" y="12" width="17" height="9">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.5 12.9961H16.3399V20.3701H0.5V12.9961Z" fill="white" />
          </mask>
          <g mask="url(#mask0_53_224)">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.42089 14.4961C4.15989 14.4961 1.99989 15.2281 1.99989 16.6731C1.99989 18.1311 4.15989 18.8701 8.42089 18.8701C12.6809 18.8701 14.8399 18.1381 14.8399 16.6931C14.8399 15.2351 12.6809 14.4961 8.42089 14.4961ZM8.42089 20.3701C6.46189 20.3701 0.499893 20.3701 0.499893 16.6731C0.499893 13.3771 5.02089 12.9961 8.42089 12.9961C10.3799 12.9961 16.3399 12.9961 16.3399 16.6931C16.3399 19.9891 11.8199 20.3701 8.42089 20.3701Z" fill="#252727" />
          </g>
          <mask id="mask1_53_224" maskUnits="userSpaceOnUse" x="3" y="0" width="11" height="12">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.10986 0.5H13.7299V11.1186H3.10986V0.5Z" fill="white" />
          </mask>
          <g mask="url(#mask1_53_224)">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.42089 1.92751C6.27989 1.92751 4.53789 3.66851 4.53789 5.80951C4.53089 7.94351 6.25989 9.68351 8.39189 9.69151L8.42089 10.4055V9.69151C10.5609 9.69151 12.3019 7.94951 12.3019 5.80951C12.3019 3.66851 10.5609 1.92751 8.42089 1.92751ZM8.42089 11.1185H8.3889C5.4669 11.1095 3.09989 8.72651 3.10989 5.80651C3.10989 2.88151 5.49189 0.499512 8.42089 0.499512C11.3489 0.499512 13.7299 2.88151 13.7299 5.80951C13.7299 8.73751 11.3489 11.1185 8.42089 11.1185Z" fill="#252727" />
          </g>
        </svg>
        <strong className="hidden">Profilo</strong>
      </NavLink>
    </nav>
  </div>
}