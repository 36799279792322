import { useQuery } from "@tanstack/react-query"
import { Utente } from "../../models/model"
import { getJwtToken } from "../auth/auth"
import { apiEndpoint, fetchJson, useMutationWithAuth } from "./config"

export function useUtente() {
  const path = '/profilo/me'
  return useQuery<Utente>({
    queryKey: ['me'],
    queryFn: async (context) => {
      const token = await getJwtToken()

      return fetch(apiEndpoint + path, {
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `bearer ${token}` as string,
        }),
      })
        .then(res => { return res.json() as Promise<Utente> })
    }
  })
}

export function useModifyUtente() {
  return useMutationWithAuth<{ id: string }, unknown, { nome: string, cognome: string }>({
    mutationKey: ['me'],
    mutationFn: async ({ nome, cognome }) => {
      const token = await getJwtToken();
      if (!token) {
        throw new Error('Errore durante la modifica dell\'utente.. no token');
      }
      const one = await fetchJson<{ id: string }>(apiEndpoint + `/profilo/me`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          nome: nome,
          cognome: cognome
        })
      });
      /* if (!one) {
        throw new Error('Errore durante la modifica dell\'utente');
      } */
      return one;
    }
  })
}