/**
 * @author Lorenzo Acciarri - JEF
 * @email lorenzo.acciarri@jef.it
 * @create date 2023-11-08 15:38:41
 * @modify date 2023-11-08 15:38:41
 * @desc Componente per bottone fixed per andare alla pagina della creazione delle prenotazioni
 */

export default function FixedButtonPrenotazione({
  text,
  version,
  disabled,
  onClick,
  disabledHint,
}: {
  text: string;
  version: "blue" | "white" | "red";
  disabled?: boolean;
  onClick: () => void;
  disabledHint?: string;
}) {
  return (
    <div className="parcheggio-milano-fiori-nord-floating-button">
      <button
        title={disabled ? disabledHint : ""}
        onClick={() => {
          if (!disabled) onClick();
        }}
        className={`big-button button-third ${disabled === true ? "disabled" : ""}  ${version}-version`}
      >
        <div className={`parcheggio-milano-fiori-nord-floating-button-text-container`}>
          <strong>{text}</strong>
          <div
            className={`parcheggio-milano-fiori-nord-floating-button-text-line parcheggio-milano-fiori-nord-floating-button-text-line-${version}-version`}
          ></div>
        </div>
      </button>
    </div>
  );
}
