import { Alert, Box, Tab, Tabs } from "@mui/material";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Prenotazione } from "../../models/model";
import { useHaDisponibilita, usePrenotazioniFuture, usePrenotazioniPassate } from "../../services/api/prenotazioni";
import { useTarghe } from "../../services/api/targhe";
import { useUtente } from "../../services/api/utente";
import { CustomTabPanel, a11yProps } from "../../theme/theme";
import FixedButtonPrenotazione from "../FixedButtonPrenotazione";
import ErroreDialog from "../shared/ErroreDialog";
import SkeletonListPrenotazioni from "../shared/SkeletonListPrenotazioni";

export default function Prenotazioni() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate()

  const {
    data: utente,
    error: isUtenteError,
    isLoading: isUtenteLoading,
  } = useUtente()
  const haDisponibilita = useHaDisponibilita();
  const {
    data: prenotazioniFuture,
    error: isPrenotazioniFutureError,
    isLoading: isPrenotazioniFutureLoading,
  } = usePrenotazioniFuture()
  const {
    data: prenotazioniPassate,
    error: isPrenotazioniPassateError,
    isLoading: isPrenotazioniPassateLoading,
  } = usePrenotazioniPassate()
  const {
    data: targhe,
    error,
    isLoading,
  } = useTarghe()
  return (
    <section>
      {(isPrenotazioniFutureError || isPrenotazioniPassateError) &&
        <ErroreDialog errorMessage="Errore durante il recupero delle prenotazioni" />
      }
      <h1>Prenotazioni</h1>
      {!isLoading && !error && targhe?.length === 0 &&
        <Alert severity="warning" className="my-4">Sembra che lei non abbia ancora registrato nessuna targa... Prima di poter prenotare è necessario registrarne almeno una.</Alert>
      }
      <div className="mt-7 mb-44">
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Attive" {...a11yProps(0)} />
              {/* <Tab label="Passate" {...a11yProps(1)} /> */}
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            {isPrenotazioniFutureLoading &&
              <SkeletonListPrenotazioni />
            }
            {!isPrenotazioniFutureLoading && prenotazioniFuture && prenotazioniFuture.length === 0 && (
              <p className="no-data">Non ci sono prenotazioni attive</p>
            )}
            <ol>
              {!isPrenotazioniFutureLoading && !isPrenotazioniFutureError && prenotazioniFuture && prenotazioniFuture.map((el, index) => {
                return <>
                  <PrintMount prenotazione={el} prenotazioni={prenotazioniFuture} />
                  <SingolaPrenotazione
                    prenotazione={el}
                    isTheNext={index === 0}
                    onClick={() => {
                      navigate(`/prenotazioni/${el.id}`)
                    }}
                  />
                </>
              })}
            </ol>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {isPrenotazioniPassateLoading &&
              <SkeletonListPrenotazioni />
            }
            {!isPrenotazioniPassateLoading && prenotazioniPassate && prenotazioniPassate.length === 0 && (
              <p className="no-data">Non ci sono prenotazioni attive passate</p>
            )}
            <ol>
              {!isPrenotazioniPassateLoading && !isPrenotazioniPassateError && prenotazioniPassate && prenotazioniPassate.map((el) => {
                return <>
                  <PrintMount prenotazione={el} prenotazioni={prenotazioniPassate} />
                  <SingolaPrenotazione prenotazione={el} />
                </>
              })}
            </ol>
          </CustomTabPanel>
        </Box>
      </div>
      {!isUtenteError && !isUtenteLoading && utente && prenotazioniFuture &&
        <FixedButtonPrenotazione
          version="blue"
          text="Prenota Ora"
          disabled={haDisponibilita !== true || targhe?.length === 0}
          onClick={() => { navigate('/prenotazioni/new') }} />
      }
    </section>
  )
}

export function SingolaPrenotazione({ isTheNext, prenotazione, onClick }: { isTheNext?: boolean, prenotazione: Prenotazione, onClick?: () => void }) {
  const ora_apertura = format(prenotazione.parcheggio.ora_apertura, 'HH:mm')
  const ora_chiusura = format(prenotazione.parcheggio.ora_chiusura, 'HH:mm')

  const ora_ingresso = format(prenotazione.ora_ingresso, 'HH:mm')
  const ora_uscita = format(prenotazione.ora_uscita, 'HH:mm')
  const targa = prenotazione.targa
  return <li
    className={`singola-prenotazione ${isTheNext ? 'active' : ''} ${prenotazione?.prenotata_da_sub ? 'prenotato-da' : ''} mt-4 ${typeof onClick === 'function' ? 'cursor-pointer' : ''}`}
    onClick={() => {
      if (typeof onClick === 'function') {
        onClick()
      }
    }} >
    <div className="singola-prenotazione-data p-7">
      <ul>
        <li className="!text-center">
          <h2> {format(prenotazione?.data, 'EEEE	', { locale: it }).charAt(0).toUpperCase() + format(prenotazione?.data, 'EEEE', { locale: it }).substring(1, 3)}</h2>
        </li>
        <li className="!text-center">
          <h2>{format(prenotazione?.data, 'dd')}</h2>
        </li>
      </ul>
    </div>
    <ul className={`singola-prenotazione-info ${isTheNext ? 'active' : ''} p-7`}>
      <li><p><strong>Parcheggio: </strong>{prenotazione.parcheggio?.nome_parcheggio}</p></li>
      <li><p><strong>Lotto: </strong>{prenotazione.lotto?.nome_parcheggio}</p></li>
      {prenotazione.lotto?.scelta_posto === true && (
        <li><p><strong>Posto: </strong>{prenotazione.posti_codice}</p></li>
      )}
      <li><p><strong>Fascia Oraria: </strong>{ora_ingresso} - {ora_uscita}</p></li>
      <li><p><strong>Orari Parcheggio: </strong>{ora_apertura} - {ora_chiusura}</p></li>
      <li><p><strong>Targa: </strong>{targa}</p></li>
      {(prenotazione?.prenotata_da_sub && !prenotazione?.autore) && (
        <li><p></p></li>
      )}
      {(prenotazione?.prenotata_da_sub && prenotazione?.autore) && (
        <li><p><strong>Prenotato da: </strong>{prenotazione.autore?.nome} {prenotazione.autore?.cognome}</p></li>
      )}
    </ul>
  </li>
}
export function PrintMount({ prenotazione, prenotazioni }: { prenotazione: Prenotazione, prenotazioni: Prenotazione[] }) {
  if (!prenotazioni) return <></>
  for (let i = 0; i < prenotazioni?.length; i++) {
    if (prenotazioni[i].id === prenotazione.id && i > 0) {
      const currentMounth = format(prenotazione?.data, 'MM')
      const prevMounth = format(prenotazioni[i - 1]?.data, 'MM')
      if (currentMounth !== prevMounth) {
        /*  prevIndex = i - 1 */
        return <li className="mt-9">
          <h2>{format(prenotazione?.data, 'MMM', { locale: it }).charAt(0).toUpperCase() + format(prenotazione?.data, 'MMM', { locale: it }).slice(1)}. {format(prenotazione?.data, 'yyyy')}</h2>
        </li>
      }
    }
  }
  return <></>
}
