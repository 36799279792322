import { Alert, TextField } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TokenContext } from "../../providers/TokenProvider";
import { useCreateTarga, useTarghe, useUpdateTarga } from "../../services/api/targhe";
import FixedButtonPrenotazione from "../FixedButtonPrenotazione";
import ErroreDialog from "../shared/ErroreDialog";
import { PageTitle } from "../shared/PageTitle";
import SuccessDialog from "../shared/SuccessDialog";
import FixedButtonDuplicaPrenotazione from "../FixedButtonDuplicaPrenotazione";
import { useUtente } from "../../services/api/utente";
import { useCliente } from "../../services/api/cliente";

export default function NuovaTarga() {
  const navigate = useNavigate();

  let { id } = useParams();
  let parsedId: number | undefined = undefined;
  const isModify = id !== "new";
  if (isModify && id) {
    parsedId = parseInt(id);
  }
  const { token } = useContext(TokenContext);
  const { data: targhe, error, isLoading } = useTarghe();
  const { data: utente, error: isUtenteError, isLoading: isUtenteLoading } = useUtente();
  const { data: cliente, error: isClienteError, isLoading: isClienteLoading } = useCliente();

  const canUserEditTarga = useMemo(() => {
    if (
      cliente &&
      utente &&
      (cliente.n_max_cambi_targa === -1 || (cliente?.n_max_cambi_targa > 0 && utente.n_cambi_targa_effettuati < cliente.n_max_cambi_targa))
    ) {
      return true;
    }
    return false;
  }, [utente, cliente]);

  const targaSelezionata = useMemo(() => {
    return (
      targhe?.find((el) => el.id === parsedId) || {
        targa: "",
        nota: "",
        targa_abilitata: false,
      }
    );
  }, [parsedId, targhe]);

  const [targa, setTarga] = useState<string>(targaSelezionata.targa);
  // const isTargaValid =  useMemo(() => {return targa !== "" || targa.length < 45 || targa !== undefined}, [targa]);
  const [nota, setNota] = useState<string>(targaSelezionata.nota);
  const [abilitata, setAbilitata] = useState<boolean>(targaSelezionata.targa_abilitata);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showSuccessMessageModificaTarga, setShowSuccessMessageModificaTarga] = useState(false);
  const [showConfirmAggiornaTargaPrenotazioni, setShowConfirmAggiornaTargaPrenotazioni] = useState(false);
  const [modalError, setModalError] = useState<boolean>(false);

  const handleSubmit = () => {
    if (!isModify) {
      mutateAsync({
        nota,
        targa,
        accessToken: token,
      })
        .then((res) => {
          if (abilitata) {
            mutateUpdateAsync({
              nota,
              targa,
              accessToken: token,
              id: res as unknown as number,
            })
              .then(() => {
                setShowSuccessMessage(true);
              })
              .catch(() => {
                setModalError(true);
              });
          }
          setShowSuccessMessage(true);
        })
        .catch(() => {
          setModalError(true);
        });
    } else {
      mutateUpdateAsync({
        nota,
        targa,
        accessToken: token,
        id: parsedId as number,
      })
        .then(() => {
          if (isTargaChanged) {
            if (utente?.abilitato_duplica_prenotazione) {
              setShowConfirmAggiornaTargaPrenotazioni(false);
            }
            setShowSuccessMessageModificaTarga(true);
          } else {
            setShowSuccessMessage(true);
          }
        })
        .catch(() => {
          setModalError(true);
        });
    }
  };

  useEffect(() => {
    if (isModify && id && !isLoading) {
      if (parsedId) {
        const finded = targhe?.find((el) => {
          return el.id === parsedId;
        });
        if (!finded) navigate(-1);
        else {
          setTarga(finded.targa.replace(/\s/g, ""));
          setNota(finded.nota);
          setAbilitata(finded.targa_abilitata);
        }
      }
    }
  }, [id, isModify, navigate, parsedId, targhe, isLoading]);

  const { mutateAsync, isPending: isCreateTargaPending, isError: isCreateTargaError, error: createTargaError } = useCreateTarga();
  const { mutateAsync: mutateUpdateAsync, isPending: isUpdateTargaPending, isError: isUpdateTargaError, error: updateTargaError } = useUpdateTarga();

  const isTargaValid = targa !== "" || targa.length < 45 || targa !== undefined;
  const isTargaChanged = targa !== targaSelezionata.targa;
/*   const targaValidation = useMemo(()=>{
    return {
      isTargaValid: targa !== "" || targa.length < 45 || targa !== undefined,
      isTargaChanged: targa !== targaSelezionata.targa
    }
  },[targa, targaSelezionata.targa]) */
  const isNotaValid = nota.length < 255;
  const isNotaChanged = nota !== targaSelezionata.nota;
  
  return (
    <section>
      <PageTitle show_back_button_on_mobile={true} title={isModify ? "Modifica targa" : "Registrazione nuova targa"} />
      <div className="mt-7">
        {!isLoading && !isClienteLoading && !isUtenteLoading && !canUserEditTarga && (
          <Alert severity="warning" className="my-4">
            Non è possibile modificare il campo Targa. La invitiamo a rivolgersi al suo Referente Master
          </Alert>
        )}
        {(isCreateTargaError || error || isUpdateTargaError) && (
          <ErroreDialog errorMessage={createTargaError?.message || updateTargaError?.message || error?.message} />
        )}
        {showSuccessMessage && <SuccessDialog />}
        {targhe && !isLoading && showSuccessMessageModificaTarga && (
          <div className="prenotazione-dialog-container">
            <ol>
              <li>
                <img src={`${process.env.PUBLIC_URL}/${"confirmed"}-icon.svg`} alt={"confirmed"} />
              </li>
              <li className="mt-10">
                <h1>Perfetto!</h1>
              </li>
              <li className="mt-4">
                <p className="prenotazione-dialog-informations">
                  <h3>Hai aggiornato la tua targa {abilitata ? " attiva" : " non attiva"}</h3>
                  <h3>{targaSelezionata.targa}</h3>
                </p>
              </li>
              <FixedButtonPrenotazione onClick={() => navigate("/prenotazioni")} version="white" disabled={false} text={"Torna alle prenotazioni"} />
            </ol>
          </div>
        )}
        {targhe && !isLoading && showConfirmAggiornaTargaPrenotazioni && (
          <div className="prenotazione-dialog-container">
            <ol>
              <li>
                <img src={`${process.env.PUBLIC_URL}/${"to_confirm"}-icon.svg`} alt={"to_confirm"} />
              </li>
              <li className="mt-4">
                <h1>Ci siamo quasi...</h1>
              </li>
              <li className="mt-4">
                <p className="prenotazione-dialog-informations">
                  {abilitata ? (
                    <span>
                      <strong>Attenzione!</strong> La targa {targa} sarà valida per prenotare da questo momento in poi. Le prenotazioni attive future che
                      potresti aver già effettuato rimangono valide per la targa {targaSelezionata.targa}.
                    </span>
                  ) : (
                    <span>
                      <strong>Attenzione!</strong> Le prenotazioni attive future che potresti aver già effettuato con la targa {targaSelezionata.targa}, non
                      saranno valide per la targa {targa}.
                    </span>
                  )}
                  <h3>
                    Vuoi utilizzare la targa {targa} per le prenotazioni attive future già effettuate con la targa {targaSelezionata.targa}?
                  </h3>
                </p>
              </li>
              <FixedButtonDuplicaPrenotazione
                onClick={() => navigate(`/targhe/${parsedId}/aggiorna-prenotazioni-future/modifica?targa=${targa}&nota=${nota}`)}
                version="white"
                disabled={isUpdateTargaPending}
                text={"Si"}
              />
              <FixedButtonPrenotazione onClick={() => handleSubmit()} version="white" disabled={isUpdateTargaPending} text={"No"} />
            </ol>
          </div>
        )}
        {!isLoading && !error && targhe && (
          <>
            <TextField
              error={!isTargaValid}
              className="w-full"
              value={targa}
              label="Targa"
              disabled={isCreateTargaPending || isUpdateTargaPending || isCreateTargaError || showSuccessMessage || !canUserEditTarga}
              onChange={(el) => {
                setTarga(el.target.value.toLocaleUpperCase().replace(/\s/g, ""));
              }}
              variant="outlined"
            />
            <TextField
              error={!isNotaValid}
              className="w-full !mt-4"
              value={nota}
              label="Nota"
              disabled={isCreateTargaPending || isUpdateTargaPending || isCreateTargaError || showSuccessMessage}
              onChange={(el) => {
                setNota(el.target.value);
              }}
              variant="outlined"
            />
            {utente?.abilitato_duplica_prenotazione && (
              <FixedButtonPrenotazione
                text={isModify ? "Salva" : "Conferma"}
                disabled={!isTargaValid || (!isTargaChanged && !isNotaChanged)}
                version="blue"
                onClick={() => {
                  isModify && isTargaChanged ? setShowConfirmAggiornaTargaPrenotazioni(true) : handleSubmit();
                }}
              />
            )}
            {!utente?.abilitato_duplica_prenotazione && (
              <FixedButtonPrenotazione
                text={isModify ? "Salva" : "Conferma"}
                disabled={!isTargaValid || (!isTargaChanged && !isNotaChanged)}
                version="blue"
                onClick={() => {
                  handleSubmit();
                }}
              />
            )}
          </>
        )}
      </div>
    </section>
  );
}
