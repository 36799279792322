import { Alert, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  useHaDisponibilita,
  usePrenotazioniFuture,
} from "../services/api/prenotazioni";
import { useTarghe } from "../services/api/targhe";
import { useUtente } from "../services/api/utente";
import FixedButtonPrenotazione from "./FixedButtonPrenotazione";
import { PrintMount, SingolaPrenotazione } from "./Prenotazioni/Prenotazioni";
import ErroreDialog from "./shared/ErroreDialog";
import SkeletonListPrenotazioni from "./shared/SkeletonListPrenotazioni";

export default function Home() {
  const navigate = useNavigate();

  const { data: targhe, error, isLoading } = useTarghe();
  const {
    data: prenotazioni,
    error: isPrenotazioniError,
    isLoading: isPrenotazioniLoading,
  } = usePrenotazioniFuture();
  const haDisponibilita = useHaDisponibilita();
  const {
    data: utente,
    error: isUtenteError,
    isLoading: isUtenteLoading,
  } = useUtente();
  return (
    <div className="mb-44">
      <section>
        {!isLoading && !error && targhe?.length === 0 && (
          <Alert severity="warning" className="my-4">
            Sembra che lei non abbia ancora registrato nessuna targa... Prima di
            poter prenotare è necessario registrarne almeno una.
          </Alert>
        )}
        {!isLoading &&
          !error &&
          targhe?.find((el) => el.targa_abilitata === true) === undefined && (
            <Alert severity="warning" className="my-4">
              Sembra che lei non abbia ancora attivato nessuna targa... Le
              consigliamo di attivarne una prima di prenotare.
            </Alert>
          )}
        {isPrenotazioniError && (
          <ErroreDialog errorMessage="Errore durante il recupero delle prenotazioni" />
        )}
        {isUtenteError && (
          <ErroreDialog errorMessage="Errore durante il recupero delle informazioni dell'utente" />
        )}
        {isUtenteLoading && <Skeleton variant="rounded" height={22} />}
        {!isUtenteLoading && (
          <h1>
            Ciao, {utente?.nome} {utente?.cognome} &#128075;
          </h1>
        )}
        {isPrenotazioniLoading && <SkeletonListPrenotazioni />}
        {!isPrenotazioniLoading && !isPrenotazioniError && (
          <div className="mt-7">
            <h2>Prenotazioni Attive</h2>
            {prenotazioni?.length === 0 && (
              <p className="no-data">Non ci sono prenotazioni attive</p>
            )}
            <ol>
              {prenotazioni &&
                prenotazioni.map((el, index) => {
                  return (
                    <>
                      <PrintMount
                        prenotazione={el}
                        prenotazioni={prenotazioni}
                      />
                      <SingolaPrenotazione
                        prenotazione={el}
                        isTheNext={index === 0}
                        onClick={() => {
                          navigate(`/prenotazioni/${el.id}`);
                        }}
                      />
                    </>
                  );
                })}
            </ol>
          </div>
        )}
      </section>
      {!isUtenteError &&
        !isUtenteLoading &&
        utente &&
        !isPrenotazioniLoading &&
        !isPrenotazioniError &&
        prenotazioni &&
        targhe && (
          <FixedButtonPrenotazione
            version="blue"
            text="Prenota Ora"
            disabled={haDisponibilita !== true || targhe?.length === 0 || utente?.abilitato_utilizzo_webapp === false}
            onClick={() => {
              navigate("/prenotazioni/new");
            }}
          />
        )}
    </div>
  );
}
