import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import { format, isSameDay } from 'date-fns';
import { useEffect, useState } from 'react';
import { Cliente, Parcheggio, Utente } from '../../models/model';
export default function ParkElement(
  { className,
    label,
    date,
    selected,
    parcheggio,
    utente,
    cliente,
    from,
    to,
    onClick
  }: {
    className?: string,
    label: string,
    selected: boolean,
    parcheggio: Parcheggio
    utente: Utente
    cliente: Cliente
    date: Date
    from: Date | undefined
    to: Date | undefined
    onClick: () => void
  }) {
  const [parkStatus, setParkStatus] = useState<'available' | 'low-availability' | 'not-available' | 'selected' | 'closed'>('available')
  const sospeso = parcheggio.parcheggio.sospeso

  const handleClick = () => {
    if (!(parcheggio.postiDisponibili.length === 0) && !sospeso) {
      onClick()
    }
  }
  const isParckClosedForTimesProvided = isSameDay(new Date(), date) && ((from && parcheggio.parcheggio.ora_apertura > from) || (to && from && parcheggio.parcheggio.ora_chiusura < to))
  useEffect(() => {

    if (selected === true) {
      setParkStatus('selected')
    } else if (cliente.durata_prenotazione === 'ora') {
      if (parcheggio.postiDisponibili.length === 0 && !isParckClosedForTimesProvided) {
        setParkStatus("not-available")
      } else if (isParckClosedForTimesProvided || sospeso) {
        setParkStatus('closed')
      }
    } else if (parcheggio.n_posti && ((parcheggio.postiDisponibili.length * 100) / parcheggio.n_posti) < 10) {
      setParkStatus("low-availability")
    } else {
      setParkStatus("available")
    }

  }, [parcheggio.postiDisponibili.length, parcheggio.n_posti, selected, parcheggio.parcheggio.ora_apertura, parcheggio.parcheggio.ora_chiusura, from, to, isParckClosedForTimesProvided, cliente.durata_prenotazione, sospeso])
  // const canSelectPlace = !(parcheggio.scelta_posto === false || utente?.abilitato_scegli_posto === false)
  return <button
    onClick={handleClick}
    className={`${className ? className + " " : ""} park-element ${selected === true ? 'active' : ''} ${parcheggio.postiDisponibili.length === 0 ? 'disabled' : ''}`}>
    <ol>
      <li>
        <div className={`park-info-container`}>
          <div className={`name-container`}>

            <strong className={`${parkStatus} !text-center`}>{parcheggio.parcheggio.nome_parcheggio} <br /> {label} </strong>
          </div>
          <section className={`info ${parcheggio.postiDisponibili.length === 0 ? 'disabled' : ''}`}>

            <li><h4><b>Sezione:</b>{label}</h4></li>
            <li><h4><b>Apertura:</b> {format(parcheggio.parcheggio.ora_apertura, 'HH:mm')}</h4></li>
            <li><h4><b>Chiusura:</b> {format(parcheggio.parcheggio.ora_chiusura, 'HH:mm')}</h4></li>
            <li><h4><b>Parcheggi Tot.:</b> {parcheggio.n_posti}</h4></li>
            {isParckClosedForTimesProvided && cliente.durata_prenotazione === 'ora' &&
              <li className='closed-park-message'><strong>Parcheggio chiuso per la fascia orari indicata.</strong></li>
            }
            {/* < li > <h4><b>Parcheggi Disp.:</b> <b className={`availability ${parkStatus}`}>{parcheggio.postiDisponibili.length}</b></h4></li> */}
            {!isParckClosedForTimesProvided &&
              < li > <h4><b>Parcheggi Disp.:</b> <b className={`availability ${parkStatus}`}>{parcheggio.postiDisponibili.length}</b></h4></li>
            }
            {sospeso &&
              <li className='closed-park-message'><strong>Parcheggio momentaneamente non disponibile.</strong></li>
            }
            {/*!canSelectPlace &&
              <li><p>Scelta posto disabilitata</p></li>
            */}
          </section>
        </div>
      </li>
      <li>
      </li>
      <li>
        <div className={`park-element-arrow-container ${parkStatus}`}>
          {parcheggio.postiDisponibili.length === 0 &&
            <BlockIcon sx={{ fontSize: 24 }} />
          }
          {parcheggio.postiDisponibili.length !== 0 && (
            <>
              {!(parcheggio.postiDisponibili.length === 0) && !selected &&
                <ArrowForwardIosIcon sx={{ fontSize: 24 }} />
              }
              {selected &&
                <CheckIcon sx={{ fontSize: 24 }} />
              }
            </>
          )}
        </div>
      </li>
    </ol>
  </button >
}